import { Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { GetLeadQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import MaskedInput from 'src/components/shared/MaskedInput/MaskedInput'
import { notifications } from 'src/components/shared/Notifications'
import { UPDATE_CUSTOMER_MUTATION } from 'src/graphql/acquisition/lead-management'
import { sanatizePhone, sanatizeZipcode } from 'src/helpers/sanatize'

import { ConfirmButton } from './ConfirmButton'

type FormValues = GetLeadQuery['GetLead']['customer']
interface CustomerFormProps {
  onSuccess: () => void
  data: FormValues
}

const CustomerForm = ({ onSuccess, data }: CustomerFormProps) => {
  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_MUTATION, {
    refetchQueries: ['GetLeadQuery'],
  })

  const form = useForm({
    initialValues: {
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      zipcode: data.zipcode,
    },
    validate: {
      phone: (value) =>
        sanatizePhone(value).length > 9 ? null : 'Telefone inválido',
      zipcode: (value) =>
        sanatizeZipcode(value).length > 7 ? null : 'CEP inválido',
    },
  })

  const handleSubmit = ({ phone, zipcode, ...values }: typeof form.values) => {
    updateCustomer({
      variables: {
        input: {
          phone: sanatizePhone(phone),
          zipcode: sanatizeZipcode(zipcode),
          ...values,
        },
      },
      onCompleted: () => {
        onSuccess()
        notifications.success({
          title: 'Dados atualizados',
          message: 'Os dados do cliente foram atualizados com sucesso.',
        })
      },
      onError: (e) => {
        if (e.message) {
          notifications.error({
            title: 'Erro ao atualizar dados',
            message: e.message,
          })
        } else {
          notifications.error({
            title: 'Erro ao atualizar dados',
            message: 'Recarregue a página e tente novamente.',
          })
        }
      },
    })
  }
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap={'xs'} mb="lg">
        <TextInput
          size="sm"
          label="Name"
          placeholder="Name"
          {...form.getInputProps('name')}
        />
        <TextInput
          size="sm"
          label="E-mail"
          placeholder="E-mail"
          {...form.getInputProps('email')}
        />
        <MaskedInput
          size="sm"
          label="Telefone"
          placeholder="Telefone"
          mask="phone"
          {...form.getInputProps('phone')}
        />
        <MaskedInput
          size="sm"
          label="CEP"
          placeholder="CEP"
          mask="cep"
          {...form.getInputProps('zipcode')}
        />

        <ConfirmButton
          onClick={() => form.onSubmit(handleSubmit)()}
          confirmQuestion="Confirmar edição?"
          loading={loading}
        >
          Salvar alterações
        </ConfirmButton>
      </Stack>
    </form>
  )
}

export default CustomerForm

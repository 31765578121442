import { Button } from '@mantine/core'
import { Lead } from 'types/graphql'
import type { OpenLeadMutation, OpenLeadMutationVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { OPEN_LEAD_MUTATION } from 'src/graphql/acquisition/lead-management'

type ReopenLeadActionProps = {
  lead: Lead
}

export const ReopenLeadAction = ({ lead }: ReopenLeadActionProps) => {
  const [openLead, { loading }] = useMutation<
    OpenLeadMutation,
    OpenLeadMutationVariables
  >(OPEN_LEAD_MUTATION, {
    refetchQueries: [
      'ListLeadsByStatusQuery',
      'ListCustomerEventsQuery',
      'GetLeadQuery',
    ],
  })

  const handleReopen = () => {
    openLead({
      variables: {
        input: {
          leadId: lead.id,
        },
      },
    })
  }

  return (
    <Button
      size="compact-md"
      radius="sm"
      fz="xs"
      color="blue"
      onClick={handleReopen}
      loading={loading}
    >
      Reabrir Oportunidade
    </Button>
  )
}

export default ReopenLeadAction

import {
  SimpleGrid,
  Stack,
  Title,
  Space,
  Group,
  Chip,
  Text,
  Box,
  ChipProps,
} from '@mantine/core'
import {
  IconMoodKid,
  IconMoodCheck,
  IconBriefcase,
  IconPlane,
  IconMusic,
  IconCertificate,
  IconClock,
  IconUser,
  IconCreditCardOff,
  IconMapPin,
  IconVideo,
  IconWorld,
  IconBrandGoogle,
  IconBrandInstagram,
  IconBrandFacebook,
  IconCurrencyDollarOff,
  IconSchoolOff,
  IconUserExclamation,
  IconVideoOff,
  IconChalkboardOff,
  IconMessageOff,
} from '@tabler/icons-react'
import {
  LeadCloseReasonEnum,
  ListCustomersForCampaignVariables,
} from 'types/graphql'

import { AudiencePreviewList } from './AudiencePreviewList'

export const FILTERS = [
  {
    key: 'idiom',
    label: 'Espanhol',
    value: 'SPANISH',
    icon: <Text>🇪🇸</Text>,
  },
  {
    key: 'idiom',
    label: 'Inglês',
    value: 'ENGLISH',
    icon: <Text>🇺🇸</Text>,
  },
  {
    key: 'selectedFranchiseeIds',
    label: 'CNA Ferraz de Vasconcelos',
    value: 420,
    icon: <IconMapPin size={12} />,
  },
  {
    key: 'selectedFranchiseeIds',
    label: 'CNA Aracaju - Treze de Julho',
    value: 1043,
    icon: <IconMapPin size={12} />,
  },
  {
    key: 'selectedFranchiseeIds',
    label: 'CNA Digital School',
    value: 1392,
    icon: <IconVideo size={12} />,
  },
  {
    key: 'closedInLast30Days',
    label: 'Perdidas nos últimos 30 dias',
    value: true,
    icon: <IconClock size={12} />,
  },
  {
    key: 'closedInLast7Days',
    label: 'Perdidas na última semana',
    value: true,
    icon: <IconClock size={12} />,
  },
  {
    key: 'closeReason',
    label: 'Objeção financeira',
    value: 'FINANCIAL_OBJECTION',
    icon: <IconCurrencyDollarOff size={12} />,
  },
  {
    key: 'closeReason',
    label: 'Sem turma para idade do aluno',
    value: 'NO_CLASS_FOR_AGE',
    icon: <IconSchoolOff size={12} />,
  },
  {
    key: 'closeReason',
    label: 'Menor idade/Não é o decisor final',
    value: 'UNDERAGE_NOT_DECISION_MAKER',
    icon: <IconUserExclamation size={12} />,
  },
  {
    key: 'closeReason',
    label: 'Não quer estudar online (5115)',
    value: 'DOES_NOT_WANT_ONLINE',
    icon: <IconVideoOff size={12} />,
  },
  {
    key: 'closeReason',
    label: 'Turma incompatível',
    value: 'INCOMPATIBLE_CLASS',
    icon: <IconChalkboardOff size={12} />,
  },
  {
    key: 'closeReason',
    label: 'Não interagiu',
    value: 'DID_NOT_INTERACT',
    icon: <IconMessageOff size={12} />,
  },
  {
    key: 'exStudents',
    label: 'Ex-alunos',
    value: true,
    icon: <IconUser size={12} />,
    disabled: true,
  },
  {
    key: 'notPaid',
    label: 'Não pagaram',
    value: true,
    icon: <IconCreditCardOff size={12} />,
    disabled: true,
  },
  {
    key: 'modality',
    label: 'Presencial',
    value: 'presential',
    icon: <IconMapPin size={12} />,
  },
  {
    key: 'modality',
    label: 'Digital',
    value: 'digital',
    icon: <IconVideo size={12} />,
  },
  {
    key: 'interests',
    label: 'Para filhos',
    value: 'forChildren',
    icon: <IconMoodKid size={12} />,
    disabled: true,
  },
  {
    key: 'interests',
    label: 'Para si',
    value: 'forParents',
    icon: <IconMoodCheck size={12} />,
    disabled: true,
  },
  {
    key: 'interests',
    label: 'Profissional',
    value: 'professional',
    icon: <IconBriefcase size={12} />,
    disabled: true,
  },
  {
    key: 'interests',
    label: 'Viagens',
    value: 'travel',
    icon: <IconPlane size={12} />,
    disabled: true,
  },
  {
    key: 'interests',
    label: 'Música',
    value: 'music',
    icon: <IconMusic size={12} />,
    disabled: true,
  },
  {
    key: 'interests',
    label: 'Certificados',
    value: 'certificates',
    icon: <IconCertificate size={12} />,
    disabled: true,
  },
  {
    key: 'origin',
    label: 'Site CNA',
    value: 'cna',
    icon: <IconWorld size={12} />,
    disabled: true,
  },
  {
    key: 'origin',
    label: 'Google',
    value: 'google',
    icon: <IconBrandGoogle size={12} />,
    disabled: true,
  },
  {
    key: 'origin',
    label: 'Instagram',
    value: 'instagram',
    icon: <IconBrandInstagram size={12} />,
    disabled: true,
  },
  {
    key: 'origin',
    label: 'Facebook',
    value: 'facebook',
    icon: <IconBrandFacebook size={12} />,
    disabled: true,
  },
]

export const getFilterLabel = (filter: (typeof FILTERS)[number]) => {
  return (
    <Group gap="xs" color="dark.9">
      {filter.icon} {filter.label}
    </Group>
  )
}

type FilterChipProps = {
  filterValue: string | boolean | number
  filterKey?: string
  disabled?: boolean
  getProps?: (value: string | boolean | number) => Partial<ChipProps>
}

const FilterChip = ({
  filterValue,
  filterKey,
  disabled,
  getProps,
}: FilterChipProps) => {
  const filter = FILTERS.find(
    (f) => f.value === filterValue && (filterKey ? f.key === filterKey : true)
  )!

  if (!filter) return null

  const chipProps = getProps ? getProps(filter.value as string) : {}

  return (
    <Chip disabled={disabled ?? filter.disabled} {...chipProps}>
      {getFilterLabel(filter)}
    </Chip>
  )
}

export const SelectAudience = ({
  filters,
  setFilters,
}: {
  filters: ListCustomersForCampaignVariables['filters']
  setFilters: (filters: ListCustomersForCampaignVariables['filters']) => void
}) => {
  const getLanguageChipProps = (language: string): Partial<ChipProps> => {
    const isSelected = filters.lead.idiom?.includes(language)

    return {
      checked: filters.lead.idiom?.includes(language),
      onClick: () =>
        setFilters({
          ...filters,
          lead: {
            ...filters.lead,
            idiom: isSelected
              ? filters.lead.idiom?.filter((l) => l !== language)
              : [...(filters.lead.idiom || []), language],
          },
        }),
    }
  }

  const getBooleanInputProps = (key: string): Partial<ChipProps> => {
    const isSelected = filters.lead[key]

    return {
      checked: isSelected,
      onClick: () =>
        setFilters({
          ...filters,
          lead: {
            ...filters.lead,
            [key]: !isSelected,
          },
        }),
    }
  }

  const getCloseReasonChipProps = (
    reason: LeadCloseReasonEnum
  ): Partial<ChipProps> => {
    const isSelected = filters.lead.closeReason?.includes(reason)

    return {
      checked: isSelected,
      onClick: () =>
        setFilters({
          ...filters,
          lead: {
            ...filters.lead,
            closeReason: isSelected
              ? filters.lead.closeReason?.filter((r) => r !== reason)
              : [...(filters.lead.closeReason || []), reason],
          },
        }),
    }
  }

  const getFranchiseeChipProps = (franchisee: number): Partial<ChipProps> => {
    const isSelected = filters.lead.selectedFranchiseeIds?.includes(franchisee)

    return {
      checked: isSelected,
      onClick: () =>
        setFilters({
          ...filters,
          lead: {
            ...filters.lead,
            selectedFranchiseeIds: isSelected
              ? filters.lead.selectedFranchiseeIds?.filter(
                  (id) => id !== franchisee
                )
              : [...(filters.lead.selectedFranchiseeIds || []), franchisee],
          },
        }),
    }
  }

  return (
    <SimpleGrid cols={2}>
      <Stack mt="lg">
        <Title order={4}>Público</Title>

        <Text size="sm">
          Segmente sua campanha combinando os filtros abaixo para atingir o
          público alvo desejado.
        </Text>

        <Space h="xs" />

        <Title order={5}>Idioma</Title>
        <Group gap="xs">
          <FilterChip filterValue="SPANISH" getProps={getLanguageChipProps} />
          <FilterChip filterValue="ENGLISH" getProps={getLanguageChipProps} />
        </Group>

        <Space h="xs" />

        <Title order={5}>Unidades</Title>
        <Group gap="xs">
          <FilterChip filterValue={420} getProps={getFranchiseeChipProps} />
          <FilterChip filterValue={1043} getProps={getFranchiseeChipProps} />
          <FilterChip filterValue={1392} getProps={getFranchiseeChipProps} />
        </Group>

        <Space h="xs" />

        <Title order={5}>Engajamento</Title>
        <Group gap="xs">
          <FilterChip
            filterValue={true}
            filterKey="closedInLast30Days"
            getProps={() => getBooleanInputProps('closedInLast30Days')}
          />
          <FilterChip
            filterValue={true}
            filterKey="closedInLast7Days"
            getProps={() => getBooleanInputProps('closedInLast7Days')}
          />
          <FilterChip filterValue={true} filterKey="exStudents" />
          <FilterChip filterValue={true} filterKey="notPaid" />
        </Group>

        <Space h="xs" />

        <Title order={5}>Motivos de perda</Title>
        <Group gap="xs">
          <FilterChip
            filterValue="FINANCIAL_OBJECTION"
            filterKey="closeReason"
            getProps={getCloseReasonChipProps}
          />
          <FilterChip
            filterValue="NO_CLASS_FOR_AGE"
            filterKey="closeReason"
            getProps={getCloseReasonChipProps}
          />
          <FilterChip
            filterValue="UNDERAGE_NOT_DECISION_MAKER"
            filterKey="closeReason"
            getProps={getCloseReasonChipProps}
          />
          <FilterChip
            filterValue="DOES_NOT_WANT_ONLINE"
            filterKey="closeReason"
            getProps={getCloseReasonChipProps}
          />
          <FilterChip
            filterValue="INCOMPATIBLE_CLASS"
            filterKey="closeReason"
            getProps={getCloseReasonChipProps}
          />
          <FilterChip
            filterValue="DID_NOT_INTERACT"
            filterKey="closeReason"
            getProps={getCloseReasonChipProps}
          />
        </Group>

        <Space h="xs" />

        <Title order={5}>Modalidade</Title>
        <Group gap="xs">
          <FilterChip filterValue="presential" filterKey="modality" disabled />
          <FilterChip filterValue="digital" filterKey="modality" disabled />
        </Group>

        <Space h="xs" />

        <Title order={5}>Interesses</Title>
        <Group gap="xs">
          <FilterChip filterValue="forChildren" filterKey="interests" />
          <FilterChip filterValue="forParents" filterKey="interests" />
          <FilterChip filterValue="professional" filterKey="interests" />
          <FilterChip filterValue="travel" filterKey="interests" />
          <FilterChip filterValue="music" filterKey="interests" />
          <FilterChip filterValue="certificates" filterKey="interests" />
        </Group>

        <Space h="xs" />

        <Title order={5}>Origem</Title>
        <Group gap="xs">
          <FilterChip filterValue="cna" filterKey="origin" />
          <FilterChip filterValue="google" filterKey="origin" />
          <FilterChip filterValue="instagram" filterKey="origin" />
          <FilterChip filterValue="facebook" filterKey="origin" />
        </Group>
      </Stack>

      <Box pt="lg">
        <Box style={{ position: 'sticky', top: 50, right: 20 }}>
          <AudiencePreviewList filters={filters} />
        </Box>
      </Box>
    </SimpleGrid>
  )
}

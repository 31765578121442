export const CREATE_LEAD_MUTATION = gql`
  mutation CreateLeadMutation($input: CreateLeadInput!) {
    CreateLead(input: $input) {
      id
    }
  }
`

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessageMutation($input: SendChatMessageInput!) {
    SendChatMessage(input: $input) {
      id
      content
      sender {
        id
        name
        type
      }
      createdAt
    }
  }
`

export const SEND_MARKETING_MESSAGE_MUTATION = gql`
  mutation SendMarketingMessageMutation($input: SendMarketingMessageInput!) {
    SendMarketingMessage(input: $input)
  }
`

export const RESEND_MESSAGE_MUTATION = gql`
  mutation ResendMessageMutation($messageId: Int!) {
    ResendChatMessage(messageId: $messageId)
  }
`

export const ASSIGN_LEAD_MUTATION = gql`
  mutation AssignLeadMutation($input: AssignLeadInput!) {
    AssignLead(input: $input) {
      id
    }
  }
`

export const CLOSE_LEAD_MUTATION = gql`
  mutation CloseLeadMutation($input: CloseLeadInput!) {
    CloseLead(input: $input) {
      id
    }
  }
`

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation UpdateCustomerMutation($input: UpdateCustomerInput!) {
    UpdateCustomer(input: $input) {
      id
    }
  }
`

export const OPEN_LEAD_MUTATION = gql`
  mutation OpenLeadMutation($input: OpenLeadInput!) {
    OpenLead(input: $input) {
      id
    }
  }
`

export const LIST_LEADS_BY_STATUS_QUERY = gql`
  query ListLeadsByStatusQuery(
    $status: LeadStatusEnum!
    $filters: ListLeadsByStatusFilters
    $take: Int
    $cursor: Int
  ) {
    ListLeadsByStatus(
      status: $status
      filters: $filters
      take: $take
      cursor: $cursor
    ) {
      total
      hasNextPage
      cursor
      leads {
        id
        status
        school
        awaitingResponse
        summary
        owner {
          id
          name
        }
        customer {
          id
          name
          email
          phone
          zipcode
        }
        notifications {
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_LEAD_BY_ID = gql`
  query GetLeadQuery($id: Int!) {
    GetLead(id: $id) {
      id
      status
      school
      summary
      awaitingResponse
      success
      closeReason
      closedAt
      customer {
        id
        name
        email
        phone
        zipcode
      }
      owner {
        id
        name
      }
      channels
      product {
        name
        idiom
        forMe
        ageGroup
        promotionCode
        knowledgeLevel
      }
      location {
        selectedFranchiseeName
        territoryFranchiseeName
        nearbyFranchises {
          id
          name
        }
      }
      extra {
        ip
        origin
        trigger
        gclid
        fbclid
        utmMedium
        utmContent
        utmSource
        utmCampaign
        utmTerm
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_CUSTOMER_CHAT_QUERY = gql`
  query GetCustomerChatQuery($customerId: Int!) {
    GetCustomerChat(customerId: $customerId) {
      canSendMessage
      reasonCantSendMessage
      suggestions {
        id
        content
      }
      messages {
        id
        content
        contentType
        mediaUrl
        deliveryStatus
        deliveredAt
        sender {
          id
          name
          type
        }
        createdAt
      }
    }
  }
`

export const LIST_CUSTOMER_EVENTS_QUERY = gql`
  query ListCustomerEventsQuery($customerId: Int!) {
    ListCustomerEvents(customerId: $customerId) {
      id
      type
      message
      createdAt
    }
  }
`

export const LIST_CUSTOMERS_QUERY = gql`
  query ListCustomersQuery(
    $page: Int!
    $perPage: Int!
    $filters: ListCustomersFilters
  ) {
    ListCustomers(page: $page, perPage: $perPage, filters: $filters) {
      customers {
        id
        name
        email
        phone
        zipcode
        leads {
          id
        }
        createdAt
        updatedAt
      }
      page
      total
    }
  }
`

export const GENERATE_CHAT_UPLOAD_URL_MUTATION = gql`
  mutation GenerateChatUploadURLMutation(
    $customerId: Int!
    $contentType: MessageContentTypeEnum!
    $filename: String!
  ) {
    GenerateChatUploadURL(
      customerId: $customerId
      contentType: $contentType
      filename: $filename
    ) {
      uploadUrl
      fileUrl
    }
  }
`

import { useEffect, useState, useRef } from 'react'

import {
  ActionIcon,
  Button,
  Group,
  Indicator,
  Loader,
  Text,
  Alert,
} from '@mantine/core'
import {
  IconPlayerPause,
  IconTrash,
  IconAlertCircle,
} from '@tabler/icons-react'
import OpusMediaRecorder from 'opus-media-recorder'
import { LiveAudioVisualizer } from 'react-audio-visualize'
import {
  GenerateChatUploadURLMutation,
  GenerateChatUploadURLMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { GENERATE_CHAT_UPLOAD_URL_MUTATION } from 'src/graphql/acquisition/lead-management'

type RecordAudioFormProps = {
  onTrashClick: () => void
  onSend: (audioFileUrl: string) => void
  customerId: number
}

export const RecordAudioForm = ({
  onTrashClick,
  onSend,
  customerId,
}: RecordAudioFormProps) => {
  const [isRecording, setIsRecording] = useState(false)
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null)
  const [sizeError, setSizeError] = useState<string | null>(null)
  const recorderRef = useRef<OpusMediaRecorder | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const chunksRef = useRef<Blob[]>([])
  const outputMimeType = 'audio/ogg'

  const [generateUploadUrlMutation, { loading: generateUploadUrlLoading }] =
    useMutation<
      GenerateChatUploadURLMutation,
      GenerateChatUploadURLMutationVariables
    >(GENERATE_CHAT_UPLOAD_URL_MUTATION)

  const MAX_FILE_SIZE_MB = 16
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024

  const startRecording = async () => {
    try {
      // Get audio stream with WhatsApp-compatible settings
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 1, // Mono for better performance
          sampleRate: 8000, // Lower sample rate (8kHz) to reduce processing needs
        },
      })
      streamRef.current = stream

      // Configure the recorder with lighter settings
      const options = {
        mimeType: 'audio/ogg;codecs=opus',
        audioBitsPerSecond: 12000, // Lower bitrate for better performance on weaker devices
      }

      // Create the recorder with explicit WASM paths
      const recorder = new OpusMediaRecorder(stream, options, {
        encoderWorkerFactory: function () {
          return new Worker('/opus/encoderWorker.umd.js')
        },
        OggOpusEncoderWasmPath: '/opus/OggOpusEncoder.wasm',
        WebMOpusEncoderWasmPath: '/opus/WebMOpusEncoder.wasm',
      })

      // Clear previous chunks
      chunksRef.current = []

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data)
        }
      }

      recorder.onerror = (event) => {
        console.error('MediaRecorder error:', event)
        stopRecording()
        setIsRecording(false)
      }

      recorder.onstop = () => {
        if (chunksRef.current.length > 0) {
          const blob = new Blob(chunksRef.current, { type: 'audio/ogg' })

          // Check if the blob size exceeds the limit
          if (blob.size > MAX_FILE_SIZE_BYTES) {
            setSizeError(
              `O áudio excede o limite de ${MAX_FILE_SIZE_MB}MB. Por favor, grave uma mensagem mais curta.`
            )
            setRecordingBlob(null)
          } else {
            setSizeError(null)
            setRecordingBlob(blob)
            setAudioUrl(URL.createObjectURL(blob))
          }
        }
        setIsRecording(false)
      }

      // Store the recorder first, then start it after a delay
      recorderRef.current = recorder

      // Use a longer delay to ensure audio context is fully initialized
      setTimeout(() => {
        if (recorderRef.current) {
          try {
            // Use smaller chunks to reduce memory pressure
            recorderRef.current.start(1000) // 1 second per chunk instead of 3
            setIsRecording(true)
          } catch (err) {
            console.error('Error starting recorder:', err)
          }
        }
      }, 300) // Slightly reduced delay for faster startup
    } catch (error) {
      console.error('Error starting recording:', error)
      setIsRecording(false)
    }
  }

  const stopRecording = () => {
    try {
      if (recorderRef.current) {
        // Only call stop if the recorder is actually recording
        if (recorderRef.current.state === 'recording') {
          recorderRef.current.stop()
        }
        recorderRef.current = null
      }

      // Stop all tracks in the stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop()
          }
        })
        streamRef.current = null
      }
    } catch (error) {
      console.error('Error stopping recording:', error)
    }
  }

  const uploadBlob = async (blob: Blob, uploadUrl: string) => {
    try {
      // Upload the OGG blob directly (it's already in the right format)
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: blob,
        headers: {
          'Content-Type': outputMimeType,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to upload blob')
      }
    } catch (error) {
      console.error('Error in uploadBlob:', error)
      throw error
    }
  }

  const handleSendAudioMessage = async () => {
    if (!recordingBlob) return

    // Clear any previous errors
    setSizeError(null)

    const timestamp = new Date().getTime()

    const {
      data: {
        GenerateChatUploadURL: { uploadUrl, fileUrl },
      },
    } = await generateUploadUrlMutation({
      variables: {
        customerId,
        contentType: 'AUDIO',
        filename: `${timestamp}.ogg`,
      },
    })

    await uploadBlob(recordingBlob, uploadUrl)
    await onSend(fileUrl)

    setAudioUrl(null)
    setRecordingBlob(null)
  }

  // Start recording immediately when component mounts
  useEffect(() => {
    let mounted = true

    // Add a delay before starting recording to ensure component is fully mounted
    const timer = setTimeout(() => {
      if (mounted) {
        startRecording().catch((err) => {
          console.error('Failed to start recording:', err)
        })
      }
    }, 300)

    // Clean up when component unmounts
    return () => {
      mounted = false
      clearTimeout(timer)
      stopRecording()
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl)
      }
    }
  }, [])

  if (generateUploadUrlLoading) {
    return (
      <Group>
        <Loader />
        <Text>Preparando envio...</Text>
      </Group>
    )
  }

  return (
    <>
      {sizeError && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Arquivo muito grande"
          color="red"
          mb="sm"
        >
          {sizeError}
        </Alert>
      )}
      <Group gap="xs">
        <ActionIcon onClick={onTrashClick} radius="xl" variant="light">
          <IconTrash size={20} />
        </ActionIcon>

        {audioUrl ? (
          <audio controls src={audioUrl} style={{ flex: 1 }} />
        ) : (
          isRecording && (
            <Group gap="lg" style={{ flex: 1 }} justify="center">
              <Indicator processing size={10} color="red" />

              {recorderRef.current && (
                <LiveAudioVisualizer
                  mediaRecorder={recorderRef.current}
                  barColor="gray"
                  width={300}
                  height={50}
                  fftSize={64}
                  maxDecibels={-10}
                  minDecibels={-90}
                  smoothingTimeConstant={0.4}
                  backgroundColor="transparent"
                />
              )}

              <ActionIcon
                onClick={stopRecording}
                radius="xl"
                variant="light"
                color="dark.3"
              >
                <IconPlayerPause size={20} />
              </ActionIcon>
            </Group>
          )
        )}

        <Button onClick={handleSendAudioMessage} disabled={!recordingBlob}>
          Enviar
        </Button>
      </Group>
    </>
  )
}

import {
  Card,
  Group,
  ThemeIcon,
  Text,
  Table,
  Button,
  Stack,
  Skeleton,
} from '@mantine/core'
import {
  IconEye,
  IconUserOff,
  IconMapPin,
  IconVideo,
} from '@tabler/icons-react'

import { useViewLead } from 'src/providers/acquisition/lead/ViewLeadProvider'

interface Customer {
  id: number
  name: string
  modality?: string
  interests?: string
  leads: Array<{
    id: number
    product: {
      idiom: string
    }
  }>
}

interface CustomerCampaignListProps {
  customers: Customer[]
  total: number
  loading: boolean
  hasFilters: boolean
}

const CustomerCampaignListSkeleton = () => {
  return (
    <Card withBorder p="sm" shadow="sm" radius="md">
      <Group justify="space-between" wrap="nowrap">
        <Skeleton height={20} width={200} />
        <Skeleton height={30} width="15vw" />
      </Group>

      <Table mt="md">
        <Table.Tbody>
          {Array.from({ length: 5 }).map((_, index) => (
            <Table.Tr key={index}>
              <Table.Td>
                <Skeleton height={24} width={60} />
              </Table.Td>
              <Table.Td>
                <Skeleton height={20} width={150} />
              </Table.Td>
              <Table.Td>
                <Skeleton height={20} width={30} />
              </Table.Td>
              <Table.Td>
                <Skeleton height={20} width={100} />
              </Table.Td>
              <Table.Td>
                <Skeleton height={20} width={120} />
              </Table.Td>
              <Table.Td>
                <Skeleton height={20} width={40} />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Card>
  )
}

export const CustomerCampaignList = ({
  customers,
  total,
  loading,
  hasFilters,
}: CustomerCampaignListProps) => {
  const { openLead } = useViewLead()

  if (loading) {
    return <CustomerCampaignListSkeleton />
  }

  return (
    <Card withBorder p="sm" shadow="sm" radius="md">
      {(customers.length === 0 || !hasFilters) && (
        <Stack align="center" justify="center" gap="xs">
          <ThemeIcon color="gray.6" size="lg" variant="transparent">
            <IconUserOff size={48} />
          </ThemeIcon>

          <Text ta="center" c="dimmed" size="xs">
            Nenhum contato encontrado. <br />
            Mude os filtros ao lado para encontrar contatos.
          </Text>
        </Stack>
      )}

      {customers.length > 0 && hasFilters && (
        <>
          <Group justify="space-between" wrap="nowrap">
            <Text ta="left" size="xs">
              Segmentação atingindo{' '}
              <span style={{ fontWeight: 600 }}>{total}</span> contatos.
            </Text>
          </Group>

          <Table mt="md">
            <Table.Tbody>
              {customers.map((contact) => (
                <Table.Tr key={contact.name}>
                  <Table.Td>
                    <Button
                      color="dark.4"
                      variant="outline"
                      size="compact-xs"
                      leftSection={<IconEye size={18} />}
                      onClick={() => openLead(contact.leads[0].id)}
                    >
                      Ver
                    </Button>
                  </Table.Td>
                  <Table.Td>{contact.name}</Table.Td>
                  <Table.Td>
                    <Group gap="xs">
                      {contact.leads[0]?.product.idiom === 'ENGLISH' && <>🇺🇸</>}
                      {contact.leads[0]?.product.idiom === 'SPANISH' && <>🇪🇸</>}
                    </Group>
                  </Table.Td>
                  <Table.Td>
                    <Group gap="xs">
                      {contact.modality === 'Presential' && (
                        <IconMapPin size={12} />
                      )}
                      {contact.modality === 'Digital' && (
                        <IconVideo size={12} />
                      )}
                      {contact.modality}
                    </Group>
                  </Table.Td>
                  <Table.Td>{contact.interests}</Table.Td>
                  <Table.Td></Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </>
      )}
    </Card>
  )
}

import { useEffect, useState } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'
import {
  ActionIcon,
  Avatar,
  Indicator,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { useIdle } from '@mantine/hooks'

import { useAuth } from 'src/auth'

const IDLE_TIME = 10 * 1000 // 10 second to stop heartbeats
// For local status logic
const AWAY_IDLE_TIME = 5 * 60 * 1000 // 5 minutes to show away status
const OFFLINE_TIME = 15 * 60 * 1000 // 15 minutes to become Offline
const HEARTBEAT_INTERVAL = 5 * 1000 // 5 seconds

const GET_PRESENCE_QUERY = gql`
  query GetPresence($salesConsultantId: Int) {
    GetPresence(salesConsultantId: $salesConsultantId) {
      status
      lastHeartbeat
    }
  }
`

const REGISTER_PRESENCE_MUTATION = gql`
  mutation RegisterPresence {
    RegisterPresence
  }
`

type Status = 'online' | 'away' | 'offline'
type StatusState = { status: Status; lastUpdatedAt: Date }

export const PRESENCE_STATUS_COLORS = {
  online: 'green',
  away: 'yellow',
  offline: 'gray',
}

export const PRESENCE_STATUS_TEXTS = {
  online: 'Online',
  away: 'Ausente',
  offline: 'Offline',
}

export function SalesConsultantAvatar() {
  const { currentUser, loading } = useAuth()

  const isIdle = useIdle(IDLE_TIME)

  const [localStatus, setLocalStatus] = useState<StatusState>({
    status: 'online',
    lastUpdatedAt: new Date(),
  })
  const [backendStatus, setBackendStatus] = useState<StatusState | undefined>()
  const [shownStatus, setShownStatus] = useState<Status>('online')

  const { data: presenceData } = useQuery(GET_PRESENCE_QUERY, {
    pollInterval: HEARTBEAT_INTERVAL,
    skip: !currentUser?.salesConsultantId,
  })

  const [registerPresence] = useMutation(REGISTER_PRESENCE_MUTATION)

  // Add heartbeat interval that only changes when localStatus changes
  useEffect(() => {
    // Only send heartbeats when online
    if (localStatus.status !== 'online') {
      return
    }

    // Set up interval to send heartbeats
    const intervalId = setInterval(() => {
      registerPresence().catch((error) => {
        console.error('Failed to register presence:', error)
      })
    }, HEARTBEAT_INTERVAL)

    // Cleanup function to clear interval when component unmounts or localStatus changes
    return () => clearInterval(intervalId)
  }, [localStatus.status, registerPresence])

  // Update backendStatus when presenceData changes
  useEffect(() => {
    if (!presenceData?.GetPresence) {
      setBackendStatus(undefined)
      return
    }

    setBackendStatus({
      status: presenceData?.GetPresence.status,
      lastUpdatedAt: new Date(presenceData?.GetPresence.lastHeartbeat),
    })
  }, [presenceData])

  // Update localStatus when isIdle changes
  useEffect(() => {
    if (!isIdle && localStatus.status !== 'online') {
      setLocalStatus({
        status: 'online',
        lastUpdatedAt: new Date(),
      })

      return
    }

    if (
      isIdle &&
      localStatus.status === 'online' &&
      localStatus.lastUpdatedAt < new Date(Date.now() - AWAY_IDLE_TIME)
    ) {
      setLocalStatus({
        status: 'away',
        lastUpdatedAt: new Date(),
      })
    } else if (
      isIdle &&
      localStatus.status === 'away' &&
      localStatus.lastUpdatedAt < new Date(Date.now() - OFFLINE_TIME)
    ) {
      setLocalStatus({
        status: 'offline',
        lastUpdatedAt: new Date(),
      })
    }

    let timeoutId: NodeJS.Timeout | undefined

    if (localStatus.status === 'away') {
      timeoutId = setTimeout(() => {
        setLocalStatus({
          status: 'offline',
          lastUpdatedAt: new Date(),
        })
      }, OFFLINE_TIME)
    }

    return () => {
      if (!isIdle && timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isIdle, localStatus])

  // Update shownStatus when localStatus or backendStatus changes
  useEffect(() => {
    if (localStatus.status === 'online') {
      setShownStatus('online')
    } else if (
      localStatus.status === 'away' ||
      localStatus.status === 'offline'
    ) {
      setShownStatus(backendStatus?.status ?? localStatus.status)
    }
  }, [localStatus, backendStatus])

  if (loading) return <Skeleton height={24} width={24} />
  if (!currentUser?.salesConsultantId) return null

  const handleStatusToggle = () => {}

  return (
    <Tooltip
      label={
        <Stack gap={1}>
          <Text size="sm">{currentUser?.name}</Text>
          <Text size="xs">{PRESENCE_STATUS_TEXTS[shownStatus]}</Text>
        </Stack>
      }
    >
      <Indicator
        color={PRESENCE_STATUS_COLORS[shownStatus]}
        position="bottom-end"
        size={12}
        offset={3}
        withBorder
      >
        <ActionIcon
          variant="transparent"
          onClick={handleStatusToggle}
          radius="xl"
          size="md"
          aria-label={
            shownStatus === 'online'
              ? 'Mudar para offline'
              : 'Mudar para online'
          }
        >
          <Avatar
            radius="xl"
            size="sm"
            color="initials"
            name={currentUser?.name}
            variant="filled"
          />
        </ActionIcon>
      </Indicator>
    </Tooltip>
  )
}

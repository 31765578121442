import { createContext, useContext, useState } from 'react'

import { Drawer, Group, Title, Button, Tooltip } from '@mantine/core'
import { IconExternalLink } from '@tabler/icons-react'

import ViewLead from 'src/pages/Acquisition/LeadsPage/components/ViewLead'

interface ViewLeadContextType {
  openLead: (id: number) => void
  closeLead: () => void
  currentLeadId: number | null
}

const ViewLeadContext = createContext<ViewLeadContextType | undefined>(
  undefined
)

export const useViewLead = () => {
  const context = useContext(ViewLeadContext)
  if (!context) {
    throw new Error('useViewLead must be used within a ViewLeadProvider')
  }
  return context
}

export const ViewLeadProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [currentLeadId, setCurrentLeadId] = useState<number | null>(null)

  const openLead = (id: number) => {
    setCurrentLeadId(id)
  }

  const closeLead = () => {
    setCurrentLeadId(null)
  }

  return (
    <ViewLeadContext.Provider value={{ openLead, closeLead, currentLeadId }}>
      {children}
      <Drawer
        trapFocus={false}
        size="95%"
        opened={currentLeadId !== null}
        onClose={closeLead}
        position="bottom"
        withCloseButton={false}
        overlayProps={{ backgroundOpacity: 0.4, blur: 4 }}
        styles={{
          content: {
            display: 'flex',
            flexDirection: 'column',
            height: '95vh',
            maxHeight: '95vh',
          },
          body: {
            padding: 0,
            flex: 1,
            height: 'calc(95vh - 60px)',
            overflow: 'hidden',
          },
        }}
      >
        <Drawer.Content>
          <Drawer.Header
            p={0}
            w="100%"
            h="60px"
            style={{
              flexShrink: 0,
              borderBottom: '1px solid #CCC',
            }}
          >
            <Group justify="space-between" align="center" p="md" w="100%">
              <Group>
                <Title order={3}>Oportunidade #{currentLeadId}</Title>
                <Button
                  variant="outline"
                  size="compact-sm"
                  radius="sm"
                  color="dark"
                  fz="xs"
                  rightSection={<IconExternalLink size={16} />}
                  onClick={() => {
                    closeLead()
                    window.open(`/acquisition/leads/${currentLeadId}`, '_blank')
                  }}
                >
                  Abrir em nova aba
                </Button>
              </Group>

              <Tooltip label="Fechar" position="left">
                <Drawer.CloseButton />
              </Tooltip>
            </Group>
          </Drawer.Header>

          <Drawer.Body>
            {currentLeadId && <ViewLead id={currentLeadId} />}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer>
    </ViewLeadContext.Provider>
  )
}

import { Button, Group, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type NeutralFeedbackFooterProps = {
  onNextActivity: () => void
  loading?: boolean
}

const NeutralFeedbackFooter = ({
  onNextActivity,
  loading,
}: NeutralFeedbackFooterProps) => {
  const { t } = useTranslation('learning')

  return (
    <Group justify="flex-end" w="100%" data-sel="neutral-feedback-footer">
      <Button
        onClick={onNextActivity}
        loading={loading}
        h={rem(50)}
        fw={500}
        miw={rem(80)}
        data-sel="next-activity"
      >
        {t('common.next')}
      </Button>
    </Group>
  )
}

export default NeutralFeedbackFooter

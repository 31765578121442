import { Title, Divider, Badge, Flex, rem } from '@mantine/core'
import { IconThumbUp } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import Markdown from 'src/components/shared/Markdown/Markdown'

import FeedbackTranslate from '../FeedbackTranslate/FeedbackTranslate'

type PositiveFeedbackProps = {
  title: string
  explanation: string
}

const PositiveFeedbackBody = ({
  title,
  explanation,
}: PositiveFeedbackProps) => {
  const { t } = useTranslation('learning')

  return (
    <Flex
      justify="flex-end"
      direction="column"
      h="auto"
      data-sel="positive-feedback-body"
    >
      <Flex direction="column" align="center">
        <IconThumbUp size={36} color="var(--mantine-color-green-7)" />
        <Badge
          size={rem(16)}
          fw="500"
          c="green.9"
          bg="green.1"
          py="lg"
          px="sm"
          mt="md"
          data-sel="positive-feedback-badge"
        >
          {t('student.activity-execution.well-done')}
        </Badge>
      </Flex>
      <Divider my="lg" />
      <Flex justify="space-between" pb="sm">
        <Title order={5} m={0} data-sel="positive-feedback-title">
          {title}
        </Title>
        <FeedbackTranslate />
      </Flex>
      <Markdown
        content={explanation}
        data-sel="positive-feedback-explanation"
      />
    </Flex>
  )
}

export default PositiveFeedbackBody

import { useState } from 'react'

import { Button, Modal, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import type {
  AssignLeadMutation,
  AssignLeadMutationVariables,
  Lead,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import SelectSalesConsultant from 'src/components/acquisition/SelectSalesConsultant'
import { ASSIGN_LEAD_MUTATION } from 'src/graphql/acquisition/lead-management'

import { ConfirmButton } from './ConfirmButton'

type AssignLeadActionProps = {
  lead: Lead
}

function AssignLeadAction({ lead }: AssignLeadActionProps) {
  const { currentUser } = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const [selectedSalesConsultantId, setSelectedSalesConsultantId] = useState<
    number | null
  >(lead.owner?.id ?? null)
  const [assignLead, { loading }] = useMutation<
    AssignLeadMutation,
    AssignLeadMutationVariables
  >(ASSIGN_LEAD_MUTATION, {
    refetchQueries: [
      'ListLeadsByStatusQuery',
      'ListCustomerEventsQuery',
      'GetLeadQuery',
    ],
  })

  const assignToMyself = () => {
    assignLead({ variables: { input: { leadId: lead.id } } })
  }

  const assignToOther = () => {
    assignLead({
      variables: {
        input: {
          leadId: lead.id,
          salesConsultantId: selectedSalesConsultantId,
        },
      },
      onCompleted: () => {
        close()
      },
    })
  }

  return (
    <>
      {lead.owner === null && (
        <Button
          size="compact-md"
          radius="sm"
          fz="xs"
          color="green"
          onClick={assignToMyself}
          loading={loading}
        >
          Atribuir a mim
        </Button>
      )}

      <Modal
        opened={opened}
        onClose={close}
        title="Atribuir oportunidade"
        centered
      >
        <Stack>
          <Text size="sm" c="dimmed">
            Selecione o consultor que irá assumir a oportunidade
          </Text>

          <SelectSalesConsultant
            onSelect={(value) => setSelectedSalesConsultantId(value)}
            value={selectedSalesConsultantId}
          />

          <ConfirmButton
            size="compact-md"
            radius="sm"
            fz="xs"
            color="green"
            onClick={assignToOther}
            confirmQuestion="Confirma atribuição?"
            loading={loading}
            disabled={!selectedSalesConsultantId}
          >
            Atribuir ao consultor
          </ConfirmButton>
        </Stack>
      </Modal>

      {currentUser.salesTeam.coordinator && (
        <Button
          size="compact-md"
          radius="sm"
          fz="xs"
          color="dark"
          onClick={open}
        >
          Atribuir a...
        </Button>
      )}
    </>
  )
}

export default AssignLeadAction

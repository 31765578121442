import { Group } from '@mantine/core'
import { Lead } from 'types/graphql'

import { useAuth } from 'src/auth'

import AssignLeadAction from './AssignLeadAction'
import CloseLeadAction from './CloseLeadAction'
import ReopenLeadAction from './ReopenLeadAction'

export const LeadActions = ({ lead }: { lead: Lead }) => {
  const { currentUser } = useAuth()

  return (
    <Group>
      {currentUser.salesConsultantId === lead.owner?.id && (
        <Group gap="xs">
          {lead.status === 'IN_PROGRESS' && <CloseLeadAction lead={lead} />}
          {lead.status === 'FINISHED' && <ReopenLeadAction lead={lead} />}
        </Group>
      )}

      <AssignLeadAction lead={lead} />
    </Group>
  )
}

import { ListCustomersQuery, ListCustomersQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { CustomerCampaignList } from './CustomerCampaignList'

const LIST_CUSTOMERS_FOR_CAMPAIGN = gql`
  query ListCustomersForCampaign(
    $filters: ListCustomersFilters
    $page: Int
    $perPage: Int
  ) {
    ListCustomers(filters: $filters, page: $page, perPage: $perPage) {
      total
      customers {
        id
        name
        leads {
          id
          product {
            idiom
            ageGroup
            knowledgeLevel
          }
        }
      }
    }
  }
`

interface AudiencePreviewListProps {
  filters: ListCustomersQueryVariables['filters']
}

export const AudiencePreviewList = ({ filters }: AudiencePreviewListProps) => {
  const { data, loading } = useQuery<
    ListCustomersQuery,
    ListCustomersQueryVariables
  >(LIST_CUSTOMERS_FOR_CAMPAIGN, {
    variables: { filters },
  })

  return (
    <CustomerCampaignList
      customers={data?.ListCustomers?.customers || []}
      total={data?.ListCustomers?.total || 0}
      loading={loading}
      hasFilters={Object.keys(filters.lead).length > 0}
    />
  )
}

import { useFeatureValue } from '@growthbook/growthbook-react'

import { useAuth } from 'src/auth'

const canAccessCampaigns = (currentUser, campaignUsers) => {
  // if (process.env.NODE_ENV === 'development') return true

  return campaignUsers.userIds.includes(currentUser?.id)
}

type FeatureFlags = {
  canAccessCampaigns: boolean
}

export const useFeatureFlags = (): FeatureFlags => {
  const { currentUser } = useAuth()
  const campaignUsers = useFeatureValue('campaigns', { userIds: [] })

  return { canAccessCampaigns: canAccessCampaigns(currentUser, campaignUsers) }
}

import { useEffect, useState } from 'react'

import {
  Box,
  Text,
  Group,
  ThemeIcon,
  Title,
  Stack,
  TextInput,
  Textarea,
  Stepper,
  Button,
  Select,
  Container,
  Tooltip,
  ActionIcon,
  SimpleGrid,
  Alert,
  Chip,
  rem,
  Divider,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  IconAlertCircle,
  IconArrowLeft,
  IconArrowRight,
  IconTargetArrow,
} from '@tabler/icons-react'

import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'
import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { useFeatureFlags } from 'src/hooks/shared/useFeatureFlags'

import { CampaignMessagePreview } from '../CampaignsPage/components/CampaignInfo'

import { AudiencePreviewList } from './components/AudiencePreviewList'
import {
  FILTERS,
  getFilterLabel,
  SelectAudience,
} from './components/SelectAudience'

const STEPS = [
  {
    label: 'Público-alvo',
    description: 'Defina a audência da campanha',
  },
  {
    label: 'Mensagem',
    description: 'Escolha a mensagem da campanha',
  },
  {
    label: 'Confirmação',
    description: 'Revise as informações e envie a campanha',
  },
]

const LIST_CAMPAIGN_MESSAGE_TEMPLATES = gql`
  query ListCampaignMessageTemplates {
    ListCampaignMessageTemplates {
      id
      name
      content
    }
  }
`

export const MessageTemplateSelect = ({
  onMessageTemplateChange,
  value,
}: {
  onMessageTemplateChange: (messageTemplate: {
    id: string
    name: string
    content: string
  }) => void
  value: string
}) => {
  const { data, loading, error } = useQuery(LIST_CAMPAIGN_MESSAGE_TEMPLATES)

  const templates =
    data?.ListCampaignMessageTemplates.map((template) => ({
      label: template.name,
      value: template.id,
    })) || []

  const handleTemplateChange = (value) => {
    const template = data?.ListCampaignMessageTemplates.find(
      (template) => template.id === value
    )
    onMessageTemplateChange(template)
  }

  const placeholder = loading
    ? 'Carregando modelos de mensagem...'
    : 'Selecione um modelo'

  return (
    <>
      <Select
        comboboxProps={{ withinPortal: false }}
        label="Modelo de mensagem"
        data={templates}
        disabled={loading}
        value={value}
        onChange={handleTemplateChange}
        placeholder={placeholder}
        required
        searchable
      />

      {error && (
        <Text size="xs" c="red">
          Erro ao carregar modelos de mensagem. Recarregue a página.
        </Text>
      )}
    </>
  )
}

const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: CreateCampaignInput!) {
    CreateCampaign(input: $input) {
      id
    }
  }
`

export const NewCampaignPage = () => {
  const { currentUser } = useAuth()
  const { canAccessCampaigns } = useFeatureFlags()
  const [step, setStep] = useState(0)
  const [createCampaign, { loading }] = useMutation(CREATE_CAMPAIGN, {
    onCompleted: (data) => {
      navigate(routes.campaign({ campaignId: data.CreateCampaign.id }))
    },
  })

  useEffect(() => {
    if (!canAccessCampaigns) {
      navigate(routes.leads())
    }
  }, [canAccessCampaigns])

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      messageTemplate: {} as {
        id: string
        name: string
        content: string
      },
      filters: {
        lead: {},
      },
    },
    validate: {
      name: (value) => (value.length < 1 ? 'Nome é obrigatório' : null),
      messageTemplate: (value) =>
        Object.keys(value).length === 0
          ? 'Selecione um modelo de mensagem'
          : null,
      filters: {
        lead: (value) =>
          Object.keys(value).length === 0
            ? 'Selecione pelo menos um filtro'
            : null,
      },
    },
  })

  const validateStep = (stepIndex: number) => {
    form.validate()

    switch (stepIndex) {
      case 0:
        if (Object.keys(form.values.filters.lead).length === 0) {
          form.setFieldError('filters', {
            lead: 'Selecione pelo menos um filtro',
          })
          return false
        }
        return true
      case 1:
        return (
          !form.validateField('name').hasError &&
          !form.validateField('messageTemplate').hasError
        )
      default:
        return true
    }
  }

  const nextStep = () => {
    const isValid = validateStep(step)
    if (isValid) {
      setStep((current) => current + 1)
    }
  }

  const onMessageTemplateChange = (messageTemplate) => {
    form.setFieldValue('messageTemplate', messageTemplate)
  }

  const handleSubmit = () => {
    const validation = form.validate()
    if (validation.hasErrors) return

    const { name, description, messageTemplate, filters } = form.values

    createCampaign({
      variables: {
        input: {
          name,
          description,
          messageTemplateId: messageTemplate.id,
          filters,
        },
      },
    })
  }

  const buttons = (
    <Group justify="flex-end">
      {step > 0 && (
        <Button
          variant="outline"
          onClick={() => setStep(step - 1)}
          leftSection={<IconArrowLeft size={16} />}
        >
          Voltar
        </Button>
      )}

      {step < STEPS.length - 1 && (
        <Button onClick={nextStep} rightSection={<IconArrowRight size={16} />}>
          Próximo
        </Button>
      )}

      {step === STEPS.length - 1 && (
        <Button onClick={handleSubmit} loading={loading}>
          Criar Campanha
        </Button>
      )}
    </Group>
  )

  return (
    <>
      <Metadata title="Nova campanha" description="Crie uma nova campanha" />

      <Box m="xl">
        <Stack gap="md">
          <Text fz="sm" c="dimmed">
            {currentUser.salesTeam.name}
          </Text>

          <Group gap="xs">
            <Tooltip label="Voltar à lista de campanhas">
              <ActionIcon
                color="dark.9"
                radius="xl"
                onClick={() => navigate(routes.campaigns())}
              >
                <IconArrowLeft size={22} />
              </ActionIcon>
            </Tooltip>

            <ThemeIcon size="lg" color="dark.9" variant="transparent">
              <IconTargetArrow size={22} />
            </ThemeIcon>

            <Title order={3}>Nova campanha</Title>
          </Group>
        </Stack>

        <Stepper active={step} onStepClick={setStep} my="xl" mb={80}>
          <Stepper.Step
            label={STEPS[0].label}
            description={STEPS[0].description}
          >
            <Container fluid mx="xl">
              <SelectAudience
                filters={form.values.filters}
                setFilters={(filters: typeof form.values.filters) =>
                  form.setFieldValue('filters', filters)
                }
              />

              {form.errors?.filters?.lead && (
                <Alert color="red" mt={50} icon={<IconAlertCircle size={16} />}>
                  {form.errors.filters.lead}
                </Alert>
              )}
            </Container>
          </Stepper.Step>

          <Stepper.Step
            label={STEPS[1].label}
            description={STEPS[1].description}
          >
            <Group p="lg" grow justify="space-around">
              <Stack maw={450}>
                <TextInput
                  required
                  label="Nome da campanha"
                  {...form.getInputProps('name')}
                />

                <Textarea
                  label="Descrição"
                  autosize
                  minRows={3}
                  maxRows={6}
                  description="Opcional"
                  {...form.getInputProps('description')}
                />

                <MessageTemplateSelect
                  onMessageTemplateChange={onMessageTemplateChange}
                  value={form.values.messageTemplate.id}
                />
              </Stack>

              {form.values.messageTemplate && (
                <CampaignMessagePreview
                  messageTemplate={form.values.messageTemplate}
                />
              )}
            </Group>
          </Stepper.Step>

          <Stepper.Step
            label={STEPS[2].label}
            description={STEPS[2].description}
          >
            <Container my="xl" fluid>
              <SimpleGrid cols={2}>
                <Stack gap={rem(40)}>
                  <Stack gap="xs">
                    <Text c="dimmed" fw={600} tt="uppercase" size="sm">
                      Campanha
                    </Text>

                    <Title order={3}>{form.values.name}</Title>
                  </Stack>

                  <Stack gap="xs">
                    <Text c="dimmed" fw={600} tt="uppercase" size="sm">
                      Descrição
                    </Text>
                    <Text>{form.values.description}</Text>
                  </Stack>

                  <Stack gap="xs">
                    <Text c="dimmed" fw={600} tt="uppercase" size="sm">
                      Segmentação
                    </Text>

                    <Group gap="xs">
                      {Object.entries(form.values.filters.lead).map(
                        ([key, value]) => {
                          // If value is array, return a list of chips
                          if (Array.isArray(value)) {
                            return value.map((v) => (
                              <Chip
                                key={v}
                                checked={false}
                                styles={{
                                  label: {
                                    cursor: 'default',
                                  },
                                }}
                              >
                                {getFilterLabel(
                                  FILTERS.find(
                                    (f) => f.key === key && f.value === v
                                  )!
                                )}
                              </Chip>
                            ))
                          }

                          return (
                            <Chip
                              key={key}
                              checked={false}
                              styles={{
                                label: {
                                  cursor: 'default',
                                },
                              }}
                            >
                              {getFilterLabel(
                                FILTERS.find((f) => f.key === key)!
                              )}
                            </Chip>
                          )
                        }
                      )}
                    </Group>
                  </Stack>
                </Stack>

                <Stack>
                  <CampaignMessagePreview
                    messageTemplate={form.values.messageTemplate}
                  />

                  <Alert
                    color="orange"
                    variant="light"
                    p="xs"
                    icon={<IconAlertCircle size={16} />}
                    radius="md"
                  >
                    Verifique as informações antes de enviar a campanha.
                  </Alert>
                </Stack>
              </SimpleGrid>

              <Divider my="xl" />

              <Stack mt="xl">
                <Group gap="xs">
                  <ThemeIcon size="lg" color="dark.9" variant="transparent">
                    <IconTargetArrow size={22} />
                  </ThemeIcon>

                  <Title order={4}>Público-alvo</Title>
                </Group>

                <AudiencePreviewList filters={form.values.filters} />
              </Stack>
            </Container>
          </Stepper.Step>
        </Stepper>

        <Group
          justify="center"
          p="md"
          bg="gray.1"
          bd="1px solid var(--mantine-color-gray-3)"
          style={{ borderRadius: 'var(--mantine-radius-md)' }}
        >
          {buttons}
        </Group>
      </Box>
    </>
  )
}

export default NewCampaignPage

import { useEffect, useRef } from 'react'

import {
  Grid,
  Group,
  Loader,
  Modal,
  ScrollArea,
  Stack,
  Tabs,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconClock, IconNote } from '@tabler/icons-react'
import {
  GetLeadQuery,
  GetLeadQueryVariables,
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { GET_LEAD_BY_ID } from 'src/graphql/acquisition/lead-management'
import {
  LIST_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATION_AS_READ_MUTATION,
} from 'src/graphql/acquisition/lead-notifications'

import { Chat } from '../components/Chat/Chat'

import { CustomerEventsTimeline } from './CustomerEventsTimeline'
import CustomerForm from './CustomerForm'
import CustomerNotes from './CustomerNotes'
import { LeadInfoSidebar } from './LeadInfoSidebar'

type ViewLeadProps = {
  id: number
}

function ViewLead({ id }: ViewLeadProps) {
  const { data, loading } = useQuery<GetLeadQuery, GetLeadQueryVariables>(
    GET_LEAD_BY_ID,
    {
      variables: { id },
    }
  )
  const [markLeadAsRead] = useMutation<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >(MARK_NOTIFICATION_AS_READ_MUTATION, {
    refetchQueries: [LIST_NOTIFICATIONS_QUERY],
  })
  const sidebarViewport = useRef<HTMLDivElement>(null)
  const timelineViewport = useRef<HTMLDivElement>(null)

  useEffect(() => {
    markLeadAsRead({ variables: { input: { leadId: id } } })
  }, [data])

  const [
    openedEditingModal,
    { open: openEditingModal, close: closeEditingModal },
  ] = useDisclosure(false)

  if (loading) {
    return (
      <Group p="md" justify="center" align="center" h="100%">
        <Loader size="xl" />
      </Group>
    )
  }

  const lead = data.GetLead

  return (
    <div style={{ height: '100%', overflow: 'hidden', flex: 1 }}>
      <Modal
        opened={openedEditingModal}
        onClose={closeEditingModal}
        title="Editando dados do cliente"
        centered
        overlayProps={{ blur: 4 }}
      >
        <Stack gap="md">
          <CustomerForm
            data={lead.customer}
            onSuccess={() => {
              closeEditingModal()
            }}
          />
        </Stack>
      </Modal>

      <Grid
        columns={12}
        gutter={0}
        h="100%"
        styles={{
          inner: { height: '100%', overflow: 'hidden' },
        }}
      >
        {/* Left Column - Lead Info */}
        <Grid.Col
          span={{ base: 12, md: 4, lg: 3 }}
          style={{
            borderRight: '1px solid #CCC',
            height: '100%',
            padding: 0,
          }}
        >
          <ScrollArea
            type="auto"
            scrollbars="y"
            h="100%"
            viewportRef={sidebarViewport}
            style={{ flex: 1 }}
          >
            <LeadInfoSidebar
              leadId={lead.id}
              openEditingModal={openEditingModal}
            />
          </ScrollArea>
        </Grid.Col>

        {/* Middle Column - Chat */}
        <Grid.Col
          span={{ base: 12, md: 8, lg: 6 }}
          style={{
            height: '100%',
            padding: 0,
          }}
        >
          <Chat customerId={lead.customer.id} />
        </Grid.Col>

        {/* Right Column - Timeline & Notes */}
        <Grid.Col
          span={{ base: 12, md: 4, lg: 3 }}
          style={{
            borderLeft: '1px solid #CCC',
            height: '100%',
            padding: 0,
            backgroundColor: 'var(--mantine-color-gray-1)',
          }}
        >
          <Tabs
            defaultValue="timeline"
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Tabs.List
              style={{
                flexShrink: 0,
                marginBottom: 'var(--mantine-spacing-xs)',
              }}
            >
              <Tabs.Tab value="timeline" leftSection={<IconClock size={16} />}>
                Eventos
              </Tabs.Tab>
              <Tabs.Tab
                value="customerNotes"
                leftSection={<IconNote size={16} />}
              >
                Anotações
              </Tabs.Tab>
            </Tabs.List>

            <div style={{ flex: 1, overflow: 'hidden' }}>
              <ScrollArea
                type="auto"
                scrollbars="y"
                viewportRef={timelineViewport}
                style={{ height: '100%' }}
              >
                <Tabs.Panel value="timeline">
                  <CustomerEventsTimeline customerId={lead.customer.id} />
                </Tabs.Panel>

                <Tabs.Panel value="customerNotes">
                  <CustomerNotes customerId={lead.customer.id} />
                </Tabs.Panel>
              </ScrollArea>
            </div>
          </Tabs>
        </Grid.Col>
      </Grid>
    </div>
  )
}

export default ViewLead

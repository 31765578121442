import {
  Box,
  Center,
  Group,
  Image,
  rem,
  Stack,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core'
import {
  IconBriefcase,
  IconChartBar,
  IconGauge,
  IconGridDots,
  IconHome2,
  IconLogout,
  IconMessages,
  IconTargetArrow,
  IconUsers,
} from '@tabler/icons-react'

import { Link, useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { NotificationsSidebar } from 'src/components/acquisition/NotificationsSidebar/NotificationsSidebar'
import { SalesConsultantAvatar } from 'src/components/acquisition/SalesConsultantAvatar'
import { useFeatureFlags } from 'src/hooks/shared/useFeatureFlags'
import { ViewLeadProvider } from 'src/providers/acquisition/lead/ViewLeadProvider'

import classes from './AcquisitionLayout.module.css'

interface NavbarLinkProps {
  icon: typeof IconHome2
  label: string
  active?: boolean
  url?: string
  disabled?: boolean
  onClick?: () => void
}

function NavbarLink({
  icon: Icon,
  label,
  active,
  url,
  onClick,
  disabled = false,
}: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        component={Link}
        {...(!disabled && { to: url, onClick })}
        aria-disabled={disabled}
        className={classes.link}
        data-active={active || undefined}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <ThemeIcon color={disabled ? 'gray.4' : 'dark.9'} variant="transparent">
          <Icon size={20} stroke={1.5} />
        </ThemeIcon>
      </UnstyledButton>
    </Tooltip>
  )
}

type AcquisitionLayoutProps = {
  children?: React.ReactNode
}

const getAcquisitionMenuItems = ({
  canAccessCampaigns,
  isCoordinator,
  isAcquisitionAdmin,
}: {
  canAccessCampaigns: boolean
  isCoordinator: boolean
  isAcquisitionAdmin: boolean
}) => {
  return [
    {
      icon: IconGauge,
      label: 'Visão Geral',
      url: '/acquisition/overview',
      disabled: !isCoordinator && !isAcquisitionAdmin,
    },
    { icon: IconBriefcase, label: 'Oportunidades', url: '/acquisition/leads' },
    { icon: IconMessages, label: 'Conversas', url: '/acquisition/chats' },
    { icon: IconUsers, label: 'Clientes', url: '/acquisition/customers' },
    {
      icon: IconTargetArrow,
      label: 'Campanhas',
      url: '/acquisition/campaigns',
      disabled: !canAccessCampaigns,
    },
    {
      icon: IconChartBar,
      label: 'Relatórios',
      url: '/acquisition/reports',
    },
  ]
}

export function AcquisitionLayout({ children }: AcquisitionLayoutProps) {
  const { pathname } = useLocation()
  const { logOut, currentUser } = useAuth()
  const { canAccessCampaigns } = useFeatureFlags()
  const menuItems = getAcquisitionMenuItems({
    canAccessCampaigns,
    isCoordinator: currentUser?.salesTeam.coordinator,
    isAcquisitionAdmin: currentUser?.contexts.some(
      (c) => c.context === 'ACQUISITION' && c.role === 'ADMIN'
    ),
  })

  const links = menuItems.map((link, k) => (
    <NavbarLink
      {...link}
      url={link.url}
      key={k}
      active={pathname.includes(link.url)}
    />
  ))

  return (
    <ViewLeadProvider>
      <Group wrap="nowrap" align="flex-start">
        <nav className={classes.navbar}>
          <Stack justify="space-between" h="100vh">
            <Stack align="center" gap="lg">
              <Center>
                <Image
                  src="/logo-cnamais.svg"
                  alt="CNA Logo"
                  height={rem(24)}
                />
              </Center>

              <Stack align="center" gap="md">
                {currentUser.salesConsultantId && (
                  <>
                    <NotificationsSidebar />
                    <SalesConsultantAvatar />
                  </>
                )}
              </Stack>
            </Stack>

            <div>
              <Stack justify="center" gap={0}>
                {links}
              </Stack>
            </div>

            <Stack justify="center" gap={0}>
              <NavbarLink icon={IconGridDots} label="Contextos" />

              <NavbarLink icon={IconLogout} label="Logout" onClick={logOut} />
            </Stack>
          </Stack>
        </nav>

        <>
          <Box pos="relative" left={80} flex={1} maw="calc(100% - 80px)">
            {children}
          </Box>
        </>
      </Group>
    </ViewLeadProvider>
  )
}

export default AcquisitionLayout

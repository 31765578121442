import { Title, Divider, Badge, Flex, rem } from '@mantine/core'
import { IconSchool } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import Markdown from 'src/components/shared/Markdown/Markdown'

import FeedbackTranslate from '../FeedbackTranslate/FeedbackTranslate'

type NeutralFeedbackBodyProps = {
  title: string
  explanation: string
}

const NeutralFeedbackBody = ({
  title,
  explanation,
}: NeutralFeedbackBodyProps) => {
  const { t } = useTranslation('learning')

  return (
    <Flex
      justify="flex-end"
      direction="column"
      h="auto"
      data-sel="neutral-feedback-body"
    >
      <Flex direction="column" align="center">
        <IconSchool size={36} color="var(--mantine-color-cnaBlue-7)" />
        <Badge
          size={rem(16)}
          fw="500"
          c="cnaBlue.7"
          bg="cnaBlue.0"
          py="lg"
          px="sm"
          mt="md"
          data-sel="neutral-feedback-badge"
        >
          {t('student.to-be-corrected')}
        </Badge>
      </Flex>
      <Divider my="lg" />
      <Flex justify="space-between" pb="sm">
        <Title order={5} m={0} data-sel="neutral-feedback-title">
          {title}
        </Title>
        <FeedbackTranslate />
      </Flex>
      <Markdown content={explanation} data-sel="neutral-feedback-explanation" />
    </Flex>
  )
}

export default NeutralFeedbackBody

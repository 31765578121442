import {
  Text,
  Alert,
  Group,
  rem,
  SimpleGrid,
  Stack,
  Title,
  Box,
  Badge,
  Button,
  Chip,
} from '@mantine/core'
import {
  IconAlertCircle,
  IconMessageCircle,
  IconSend,
} from '@tabler/icons-react'
import type {
  CampaignStatus,
  SendCampaign,
  SendCampaignVariables,
  GetCampaign,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { FormattedMessage } from 'src/pages/Acquisition/LeadsPage/components/FormattedMessage/FormattedMessage'

import { GET_CAMPAIGN } from '../../CampaignPage/CampaignPage'
import {
  getFilterLabel,
  FILTERS,
} from '../../NewCampaignPage/components/SelectAudience'

export const CampaignMessagePreview = ({ messageTemplate }) => {
  if (!messageTemplate?.id) {
    return <Box />
  }
  return (
    <Stack gap="sm">
      <Group gap="xs">
        <IconMessageCircle size={16} />
        <Text>Preview</Text>
      </Group>

      <Text size="xs" c="dimmed">
        Veja como a mensagem é exibida para o público-alvo
      </Text>

      <Box pos="relative">
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 8,
            backgroundColor: '#e5e5f7',
            opacity: 0.2,
            border: '1px solid var(--mantine-color-dark-7)',
            backgroundImage:
              'linear-gradient(to right, #9ec4ae, #9ec4ae 8.5px, #e5e5f7 8.5px, #e5e5f7 )',
            backgroundSize: '17px 100%',
          }}
        ></Box>

        <Group
          m="xl"
          pos="relative"
          key={messageTemplate.id}
          align="flex-start"
          justify="flex-start"
          gap="sm"
        >
          <Stack gap={0} align="flex-start" flex={1}>
            <Group justify="flex-start" gap="xs" wrap="nowrap">
              <Stack gap={2} align="flex-start">
                <Box
                  px={10}
                  py={8}
                  maw={300}
                  style={{
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'white',
                    color: 'black',
                    wordWrap: 'break-word',
                    alignSelf: 'flex-start',
                    borderRadius: 8,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Stack gap={3}>
                    <Text
                      size="sm"
                      ta="left"
                      style={{ whiteSpace: 'pre-line' }}
                      fw={500}
                      ff="sans-serif"
                    >
                      <FormattedMessage message={messageTemplate.content} />
                    </Text>

                    <Text fz={11} c="dimmed" ta="right">
                      15:32
                    </Text>
                  </Stack>
                </Box>
              </Stack>
            </Group>
          </Stack>
        </Group>
      </Box>
    </Stack>
  )
}

export const CampaignStatusBadge = ({ status }: { status: CampaignStatus }) => {
  const colors = {
    DRAFT: 'gray',
    ACTIVE: 'blue',
    COMPLETED: 'green',
  }

  const labels = {
    DRAFT: 'Rascunho',
    ACTIVE: 'Ativa',
    COMPLETED: 'Concluída',
  }

  return (
    <Badge color={colors[status]} size="md">
      {labels[status]}
    </Badge>
  )
}

const SEND_CAMPAIGN_MUTATION = gql`
  mutation SendCampaign($campaignId: Int!) {
    SendCampaign(campaignId: $campaignId) {
      id
    }
  }
`

type CampaignInfoProps = {
  showCreationAlert?: boolean
  campaign: {
    id: number
    name: string
    status?: CampaignStatus
    filters: GetCampaign['GetCampaign']['filters']
    description: string
    messageTemplate: {
      id: string
      name: string
      content: string
    }
    deliveries: {
      id: string
      customerId: string
    }[]
  }
}

export const CampaignInfo = ({
  campaign,
  showCreationAlert = false,
}: CampaignInfoProps) => {
  const [sendCampaign, { loading }] = useMutation<
    SendCampaign,
    SendCampaignVariables
  >(SEND_CAMPAIGN_MUTATION, {
    variables: { campaignId: campaign.id },
    refetchQueries: [GET_CAMPAIGN],
  })

  return (
    <SimpleGrid cols={2}>
      <Stack gap={rem(40)}>
        <SimpleGrid cols={2}>
          <Stack gap={rem(40)}>
            <Stack gap="xs">
              <Text c="dimmed" fw={600} tt="uppercase" size="sm">
                Campanha
              </Text>

              <Title order={3}>{campaign.name}</Title>
            </Stack>
          </Stack>

          {campaign.status && (
            <Group>
              <Stack gap="xs">
                <Text c="dimmed" fw={600} tt="uppercase" size="sm">
                  Status
                </Text>

                <CampaignStatusBadge status={campaign.status} />
              </Stack>

              {campaign.status === 'DRAFT' && (
                <Button
                  rightSection={<IconSend size={16} />}
                  loading={loading}
                  onClick={() => sendCampaign()}
                >
                  Disparar campanha
                </Button>
              )}
            </Group>
          )}

          <Stack gap="xs" mt="lg">
            <Text c="dimmed" fw={600} tt="uppercase" size="sm">
              Descrição
            </Text>
            <Text>{campaign.description}</Text>
          </Stack>
        </SimpleGrid>

        <Stack gap="xs" mt="lg">
          <Text c="dimmed" fw={600} tt="uppercase" size="sm">
            Segmentação
          </Text>

          <Group gap="xs">
            {Object.entries(campaign.filters.lead || {})
              .filter(([key]) => !key.startsWith('__'))
              .filter(([_, value]) => value)
              .map(([key, value]) => {
                if (Array.isArray(value)) {
                  return value.map((v) => (
                    <Chip
                      key={v}
                      checked={false}
                      styles={{
                        label: {
                          cursor: 'default',
                        },
                      }}
                    >
                      {getFilterLabel(
                        FILTERS.find((f) => f.key === key && f.value === v)!
                      )}
                    </Chip>
                  ))
                }

                return (
                  <Chip
                    key={key}
                    checked={false}
                    styles={{
                      label: {
                        cursor: 'default',
                      },
                    }}
                  >
                    {getFilterLabel(FILTERS.find((f) => f.key === key)!)}
                  </Chip>
                )
              })}
          </Group>
        </Stack>
      </Stack>

      <Stack>
        <CampaignMessagePreview messageTemplate={campaign.messageTemplate} />

        {showCreationAlert && (
          <Alert
            color="orange"
            variant="light"
            p="xs"
            icon={<IconAlertCircle size={16} />}
            radius="md"
          >
            Verifique as informações antes de enviar a campanha.
          </Alert>
        )}
      </Stack>
    </SimpleGrid>
  )
}

import { Buffer } from 'buffer'

import { createDbAuthClient, createAuth } from '@redwoodjs/auth-dbauth-web'
import WebAuthnClient from '@redwoodjs/auth-dbauth-web/webAuthn'

const dbAuthClient = createDbAuthClient({
  fetchConfig: { credentials: 'include' },
  webAuthn: new WebAuthnClient(),
})

const { AuthProvider, useAuth, AuthContext } = createAuth(dbAuthClient)

const loginWithOpenId = (currentUser, { openIdToken, openIdSecret }) => {
  const oidcUrl = process.env.OIDC_URL

  if (!oidcUrl) {
    return null
  }

  const input = JSON.stringify({
    account: {
      id: currentUser.id,
      email: currentUser.email,
    },
    openIdSecret,
  })

  const encodedInput = Buffer.from(input).toString('base64')

  return `${oidcUrl}/interaction/${openIdToken}/set_account?input=${encodedInput}`
}

const abortOpenIdLogin = ({ openIdToken, openIdSecret }) => {
  const oidcUrl = process.env.OIDC_URL

  if (!oidcUrl) {
    return null
  }

  const input = JSON.stringify({
    openIdSecret,
  })

  const encodedInput = Buffer.from(input).toString('base64')
  return `${oidcUrl}/interaction/${openIdToken}/abort?input=${encodedInput}`
}

export { AuthProvider, useAuth, AuthContext, loginWithOpenId, abortOpenIdLogin }

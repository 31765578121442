import React from 'react'

import { AspectRatio, Card } from '@mantine/core'

interface ReportCardProps {
  children: React.ReactNode
  size?: 'sm' | 'md' | 'lg'
}

export default function ReportCard({ children, size = 'md' }: ReportCardProps) {
  const sizes = {
    sm: {
      ratio: 1 / 1,
    },
    md: {
      ratio: 4 / 3,
    },
    lg: {
      ratio: 5 / 3,
    },
  }
  return (
    <Card bg="gray.2" radius="md">
      <AspectRatio ratio={sizes[size].ratio}>{children}</AspectRatio>
    </Card>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import {
  Anchor,
  Avatar,
  Box,
  Group,
  Image,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
  Transition,
} from '@mantine/core'
import { useDebouncedCallback } from '@mantine/hooks'
import {
  IconAlertCircle,
  IconCheck,
  IconChecks,
  IconClock,
  IconLoader,
  IconRobotFace,
  IconTargetArrow,
} from '@tabler/icons-react'
import {
  GetCustomerChatQuery,
  GetCustomerChatQueryVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import SendMessageForm from 'src/components/acquisition/chat/SendMessageForm'
import {
  GET_CUSTOMER_CHAT_QUERY,
  RESEND_MESSAGE_MUTATION,
} from 'src/graphql/acquisition/lead-management'
import { dayjs, formatDate, formatDateRelative } from 'src/lib/date'
import { FormattedMessage } from 'src/pages/Acquisition/LeadsPage/components/FormattedMessage/FormattedMessage'

type Message = GetCustomerChatQuery['GetCustomerChat']['messages'][number]

type ChatProps = {
  initialMessages?: Message[]
  customerId: number
}

const MarketingMessage = ({ message }: { message: Message }) => {
  const [showTimestamp, setShowTimestamp] = useState(false)

  const showTimestampDebounced = useDebouncedCallback(() => {
    setShowTimestamp(true)
  }, 1000)

  const hideTimestampDebounced = useDebouncedCallback(() => {
    setShowTimestamp(false)
  }, 1000)

  return (
    <Group key={message.id} align="flex-end" justify="flex-end" gap="sm">
      <Stack gap={0} align="flex-end" flex={1}>
        <Group justify="flex-end" gap="xs" wrap="nowrap">
          <Stack gap={2} align="flex-end">
            <Box
              px={10}
              py={8}
              maw="60%"
              bd={
                message.deliveryStatus === 'ERROR'
                  ? '1px dashed var(--mantine-color-red-6)'
                  : 'none'
              }
              style={(theme) => ({
                backgroundColor:
                  message.deliveryStatus === 'ERROR'
                    ? theme.colors.white
                    : theme.colors.violet[6],
                color:
                  message.deliveryStatus === 'ERROR'
                    ? theme.colors.red[6]
                    : 'white',
                wordWrap: 'break-word',
                alignSelf: 'flex-end',
                borderRadius: 8,
              })}
              onMouseEnter={showTimestampDebounced}
              onMouseLeave={hideTimestampDebounced}
            >
              <Group gap="xs">
                <Text size="sm" ta="left" style={{ whiteSpace: 'pre-line' }}>
                  <FormattedMessage message={message.content} />
                </Text>
              </Group>
            </Box>

            <MessageDeliveryStatus message={message} isLastInGroup={true} />

            <Transition
              mounted={showTimestamp}
              transition="fade"
              duration={200}
            >
              {(styles) => (
                <Tooltip label={formatDate(message.createdAt)} fz="xs">
                  <Text fz={10} c="dimmed" style={styles}>
                    {dayjs(message.createdAt).isSame(dayjs(), 'day')
                      ? formatDateRelative(message.createdAt)
                      : formatDate(message.createdAt)}
                  </Text>
                </Tooltip>
              )}
            </Transition>
          </Stack>

          <Tooltip label="Mensagem de marketing enviada automaticamente">
            <Avatar
              color="violet.6"
              size="sm"
              radius="xl"
              name="CNA"
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <IconTargetArrow size={16} />
            </Avatar>
          </Tooltip>
        </Group>
      </Stack>
    </Group>
  )
}

const BotMessage = ({ message }: { message: Message }) => {
  return (
    <Group key={message.id} align="flex-end" justify="flex-end" gap="sm">
      <Stack gap={0} align="flex-end" flex={1}>
        <Group justify="flex-end" gap="xs" wrap="nowrap">
          <Stack gap={2} align="flex-end">
            <Box
              px={10}
              py={8}
              maw="60%"
              style={(theme) => ({
                backgroundColor: theme.colors.violet[6],
                color: 'white',
                wordWrap: 'break-word',
                alignSelf: 'flex-end',
                borderRadius: 8,
              })}
            >
              <Group gap="xs">
                <Text size="sm" ta="left" style={{ whiteSpace: 'pre-line' }}>
                  <FormattedMessage message={message.content} />
                </Text>
              </Group>
            </Box>

            <MessageDeliveryStatus message={message} isLastInGroup={true} />

            <Tooltip label={formatDate(message.createdAt)} fz="xs">
              <Text fz={10} c="dimmed">
                {dayjs(message.createdAt).isSame(dayjs(), 'day')
                  ? formatDateRelative(message.createdAt)
                  : formatDate(message.createdAt)}
              </Text>
            </Tooltip>
          </Stack>

          <Tooltip label="Mensagem enviada automaticamente pelo bot">
            <Avatar
              color="violet.6"
              size="sm"
              radius="xl"
              name="CNA"
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <IconRobotFace size={16} />
            </Avatar>
          </Tooltip>
        </Group>
      </Stack>
    </Group>
  )
}

// New function to handle timestamp display
const MessageTimestamp = ({
  message,
  isLastInGroup,
  showTimestamp,
}: {
  message: Message
  isLastInGroup: boolean
  showTimestamp?: boolean
}) => {
  return (
    <>
      {isLastInGroup ? (
        <Tooltip label={formatDate(message.createdAt)} fz="xs">
          <Text fz={10} c="dimmed">
            {dayjs(message.createdAt).isSame(dayjs(), 'day')
              ? formatDateRelative(message.createdAt)
              : formatDate(message.createdAt)}
          </Text>
        </Tooltip>
      ) : (
        <Transition mounted={!!showTimestamp} transition="fade" duration={200}>
          {(styles) => (
            <Text fz={10} c="dimmed" style={styles}>
              {dayjs(message.createdAt).isSame(dayjs(), 'day')
                ? formatDateRelative(message.createdAt)
                : formatDate(message.createdAt)}
            </Text>
          )}
        </Transition>
      )}
    </>
  )
}

// New component to handle delivery status icons
const MessageDeliveryStatus = ({
  message,
  isLastInGroup,
}: {
  message: Message
  isLastInGroup: boolean
}) => {
  // Always show READ, PENDING and ERROR statuses, but only show DELIVERED and SENT for the last message in a group
  const shouldShowStatus =
    isLastInGroup ||
    message.deliveryStatus === 'READ' ||
    message.deliveryStatus === 'PENDING' ||
    message.deliveryStatus === 'ERROR'

  if (!shouldShowStatus) return null

  return (
    <>
      {message.deliveryStatus === 'READ' && (
        <Tooltip label="Lido" fz="xs">
          <IconChecks
            style={{ margin: '-2px 0' }}
            size={12}
            color="var(--mantine-color-blue-6)"
            stroke={2.5}
          />
        </Tooltip>
      )}

      {message.deliveryStatus === 'DELIVERED' && (
        <Tooltip label="Entregue" fz="xs">
          <IconChecks
            style={{ margin: '-2px 0' }}
            size={12}
            color="var(--mantine-color-gray-6)"
            stroke={2.5}
          />
        </Tooltip>
      )}

      {message.deliveryStatus === 'SENT' && (
        <Tooltip label="Enviado" fz="xs">
          <IconCheck
            style={{ margin: '-2px 0' }}
            size={12}
            color="var(--mantine-color-gray-6)"
            stroke={2.5}
          />
        </Tooltip>
      )}

      {message.deliveryStatus === 'PENDING' && (
        <Tooltip label="Enviando mensagem" fz="xs">
          <IconClock size={12} color="var(--mantine-color-gray-6)" />
        </Tooltip>
      )}

      {message.deliveryStatus === 'ERROR' &&
        (message.sender.type === 'MARKETING' ? (
          <MarketingMessageErrorState />
        ) : (
          <ConsultantMessageErrorState message={message} />
        ))}
    </>
  )
}

// Extracted component for consultant message
const ConsultantMessageItem = ({
  message,
  isLastInGroup,
}: {
  message: Message
  isLastInGroup: boolean
}) => {
  const [showTimestamp, setShowTimestamp] = useState(false)

  const showTimestampDebounced = useDebouncedCallback(() => {
    setShowTimestamp(true)
  }, 1000)

  const hideTimestampDebounced = useDebouncedCallback(() => {
    setShowTimestamp(false)
  }, 1000)

  return (
    <React.Fragment>
      <Box
        px={10}
        py={8}
        my={4}
        maw="60%"
        bd={
          message.deliveryStatus === 'ERROR'
            ? '1px dashed var(--mantine-color-red-6)'
            : 'none'
        }
        style={(theme) => ({
          backgroundColor:
            message.deliveryStatus === 'ERROR'
              ? theme.colors.white
              : theme.colors.blue[6],
          color:
            message.deliveryStatus === 'ERROR' ? theme.colors.red[6] : 'white',
          wordWrap: 'break-word',
          alignSelf: 'flex-end',
          borderRadius: 8,
          borderBottomRightRadius: isLastInGroup ? 0 : 8,
          borderBottomLeftRadius: 8,
        })}
        onMouseEnter={showTimestampDebounced}
        onMouseLeave={hideTimestampDebounced}
      >
        {message.contentType === 'AUDIO' && (
          <audio src={message.mediaUrl} controls />
        )}

        {message.contentType === 'IMAGE' && (
          <Image src={message.mediaUrl} alt="Mensagem de cliente" />
        )}

        {message.content?.length > 0 && (
          <Text size="sm" ta="left" style={{ whiteSpace: 'pre-line' }}>
            <FormattedMessage message={message.content} />
          </Text>
        )}
      </Box>

      <MessageDeliveryStatus message={message} isLastInGroup={isLastInGroup} />

      <MessageTimestamp
        message={message}
        isLastInGroup={isLastInGroup}
        showTimestamp={showTimestamp}
      />
    </React.Fragment>
  )
}

// Extracted component for customer message
const CustomerMessageItem = ({
  message,
  isLastInGroup,
}: {
  message: Message
  isLastInGroup: boolean
}) => {
  const [showTimestamp, setShowTimestamp] = useState(false)

  const showTimestampDebounced = useDebouncedCallback(() => {
    setShowTimestamp(true)
  }, 300)

  const hideTimestampDebounced = useDebouncedCallback(() => {
    setShowTimestamp(false)
  }, 100)

  return (
    <React.Fragment>
      <Box
        px={10}
        py={8}
        my={4}
        maw="60%"
        style={(theme) => ({
          backgroundColor: theme.colors.gray[3],
          color: theme.black,
          wordWrap: 'break-word',
          alignSelf: 'flex-start',
          borderRadius: 8,
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: isLastInGroup ? 0 : 8,
        })}
        onMouseEnter={showTimestampDebounced}
        onMouseLeave={hideTimestampDebounced}
      >
        {message.contentType === 'AUDIO' && (
          <audio src={message.mediaUrl} controls />
        )}

        {message.contentType === 'IMAGE' && (
          <Tooltip label="Clique para ver a imagem">
            <Anchor href={message.mediaUrl} target="_blank">
              <Image src={message.mediaUrl} alt="Mensagem de cliente" />
            </Anchor>
          </Tooltip>
        )}

        {message.content?.length > 0 && (
          <Text size="sm" ta="left" style={{ whiteSpace: 'pre-line' }}>
            <FormattedMessage message={message.content} />
          </Text>
        )}
      </Box>

      <MessageTimestamp
        message={message}
        isLastInGroup={isLastInGroup}
        showTimestamp={showTimestamp}
      />
    </React.Fragment>
  )
}

export const Chat: React.FC<ChatProps> = ({
  initialMessages = [],
  customerId,
}) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages)
  const viewport = useRef<HTMLDivElement>(null)

  const { data } = useQuery<
    GetCustomerChatQuery,
    GetCustomerChatQueryVariables
  >(GET_CUSTOMER_CHAT_QUERY, {
    variables: { customerId },
    pollInterval: 1000,
  })

  useEffect(() => {
    if (!data?.GetCustomerChat) return

    setMessages(data.GetCustomerChat.messages)
  }, [data])

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom()
    }
  }, [messages])

  const scrollToBottom = () =>
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: 'instant',
    })

  // Group messages by date
  const groupMessagesByDate = (messages: Message[]) => {
    const groups: { [key: string]: Message[] } = {}

    messages.forEach((message) => {
      const date = dayjs(message.createdAt).format('YYYY-MM-DD')
      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(message)
    })

    return Object.entries(groups).map(([date, messages]) => ({
      date,
      messages,
    }))
  }

  // Group consecutive messages from the same sender
  const groupConsecutiveMessages = (messages: Message[]) => {
    const groups: Message[][] = []
    let currentGroup: Message[] = []

    messages.forEach((message, index) => {
      if (index === 0) {
        currentGroup.push(message)
        return
      }

      const prevMessage = messages[index - 1]

      if (
        prevMessage.sender.type === message.sender.type &&
        prevMessage.sender.id === message.sender.id
      ) {
        currentGroup.push(message)
        return
      }

      groups.push([...currentGroup])
      currentGroup = [message]
    })

    if (currentGroup.length > 0) {
      groups.push(currentGroup)
    }

    return groups
  }

  // Format date label
  const getDateLabel = (dateStr: string) => {
    const date = dayjs(dateStr)
    const today = dayjs().startOf('day')
    const yesterday = today.subtract(1, 'day')

    if (date.isSame(today, 'day')) {
      return 'Hoje'
    } else if (date.isSame(yesterday, 'day')) {
      return 'Ontem'
    } else {
      return formatDate(date.toISOString(), 'DD/MM/YYYY')
    }
  }

  // Group messages by date and then by consecutive sender
  const dateGroups = groupMessagesByDate(messages)

  return (
    <Stack styles={{ root: { height: '100%' } }} gap="xs">
      <ScrollArea
        type="scroll"
        scrollbars="y"
        viewportRef={viewport}
        h="100%"
        styles={{
          viewport: {
            alignContent: messages.length > 0 ? 'flex-end' : 'center',
          },
        }}
      >
        <Stack p="xs" pt={24} px={16} justify="center">
          {messages.length === 0 ? (
            <Text
              display="block"
              c="dark.1"
              size="sm"
              ta="center"
              style={{ alignSelf: 'center' }}
            >
              Sem mensagens
            </Text>
          ) : (
            dateGroups.map(({ date, messages }) => (
              <Stack key={date} gap="md">
                <Text
                  ta="center"
                  fz="xs"
                  c="dark.2"
                  py={4}
                  px={8}
                  style={(theme) => ({
                    backgroundColor: theme.colors.gray[2],
                    borderRadius: theme.radius.sm,
                    alignSelf: 'center',
                  })}
                >
                  {getDateLabel(date)}
                </Text>

                {groupConsecutiveMessages(messages).map((group, groupIndex) => {
                  const firstMessage = group[0]

                  if (firstMessage.sender.type === 'MARKETING') {
                    return group.map((message) => (
                      <MarketingMessage key={message.id} message={message} />
                    ))
                  }

                  if (firstMessage.sender.type === 'BOT') {
                    return group.map((message) => (
                      <BotMessage key={message.id} message={message} />
                    ))
                  }

                  if (firstMessage.sender.type === 'CONSULTANT') {
                    return (
                      <Group
                        key={groupIndex}
                        align="flex-end"
                        justify="flex-end"
                        gap="sm"
                      >
                        <Stack gap={0} align="flex-end" flex={1}>
                          <Text fw={500} size="xs" c="dark.3">
                            {firstMessage.sender.name}
                          </Text>

                          {group.map((message, messageIndex) => (
                            <ConsultantMessageItem
                              key={message.id}
                              message={message}
                              isLastInGroup={messageIndex === group.length - 1}
                            />
                          ))}
                        </Stack>

                        <Avatar
                          color="initials"
                          size="sm"
                          radius="xl"
                          variant="outline"
                          name={firstMessage.sender.name}
                        />
                      </Group>
                    )
                  }

                  if (firstMessage.sender.type === 'CUSTOMER') {
                    return (
                      <Group
                        key={groupIndex}
                        align="flex-end"
                        justify="left"
                        gap="sm"
                      >
                        <Stack gap={0} align="flex-start" flex={1}>
                          <Text fw={500} size="xs" c="dark.3">
                            {firstMessage.sender.name}
                          </Text>

                          {group.map((message, messageIndex) => (
                            <CustomerMessageItem
                              key={message.id}
                              message={message}
                              isLastInGroup={messageIndex === group.length - 1}
                            />
                          ))}
                        </Stack>
                      </Group>
                    )
                  }

                  return null
                })}
              </Stack>
            ))
          )}
        </Stack>
      </ScrollArea>

      <SendMessageForm
        customerId={customerId}
        suggestions={data?.GetCustomerChat?.suggestions}
        canSendMessage={data?.GetCustomerChat?.canSendMessage}
        reasonCantSendMessage={data?.GetCustomerChat?.reasonCantSendMessage}
      />
    </Stack>
  )
}

// Helper component to extract the error state logic
const ConsultantMessageErrorState = ({ message }: { message: Message }) => {
  const [resendMessage, { loading: resendMessageLoading }] = useMutation(
    RESEND_MESSAGE_MUTATION
  )

  return (
    <>
      {resendMessageLoading && (
        <Tooltip label="Tentando enviar novamente" fz="xs">
          <Group gap={5}>
            <IconLoader size={16} color="var(--mantine-color-red-6)" />
          </Group>
        </Tooltip>
      )}

      {!resendMessageLoading && (
        <Tooltip label="Clique para enviar novamente" fz="xs">
          <Group gap={5}>
            <Anchor
              size="xs"
              c="red.6"
              td="underline"
              onClick={() =>
                resendMessage({ variables: { messageId: message.id } })
              }
            >
              Erro ao enviar. Tentar novamente?
            </Anchor>

            <IconAlertCircle size={16} color="var(--mantine-color-red-6)" />
          </Group>
        </Tooltip>
      )}
    </>
  )
}

// Helper component to extract the error state logic for marketing messages
const MarketingMessageErrorState = () => {
  return (
    <Tooltip label="Falha ao enviar" fz="xs">
      <Group gap={5}>
        <IconAlertCircle size={12} />
      </Group>
    </Tooltip>
  )
}

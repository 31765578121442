import { createRef, useLayoutEffect, useState } from 'react'

import { AppShell, rem } from '@mantine/core'

import ActivityResult from 'src/components/learning/ActivityResult/ActivityResult'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { ActivityProvider } from 'src/providers/learning/activity/ActivityProvider'
import { ActivityResolverProvider } from 'src/providers/learning/activity/ActivityResolverProvider'
type LearningActivityLayoutProps = {
  children?: React.ReactNode
}

const LearningActivityLayout = ({ children }: LearningActivityLayoutProps) => {
  const isSmallScreen = useIsSmallScreen()
  const footerRef = createRef<HTMLDivElement>()
  const [paddingBottom, setPaddingBottom] = useState(0)

  useLayoutEffect(() => {
    if (!footerRef.current) return

    const footerHeight = footerRef.current.offsetHeight

    setPaddingBottom(footerHeight)

    const resizeObserver = new ResizeObserver(() => {
      const newFooterHeight = footerRef.current.offsetHeight

      setPaddingBottom(newFooterHeight)
    })

    resizeObserver.observe(footerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [footerRef])

  return (
    <ActivityProvider>
      <ActivityResolverProvider>
        <AppShell className="learning-activity-page">
          <AppShell.Main pb={rem(paddingBottom)}>{children}</AppShell.Main>
          {isSmallScreen && (
            <AppShell.Footer
              ref={footerRef}
              hiddenFrom="md"
              data-sel="activity-footer"
            >
              <ActivityResult />
            </AppShell.Footer>
          )}
        </AppShell>
      </ActivityResolverProvider>
    </ActivityProvider>
  )
}

export default LearningActivityLayout

import {
  Button,
  Group,
  Modal,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import {
  CloseLeadMutation,
  CloseLeadMutationVariables,
  Lead,
  LeadCloseReasonEnum,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { CLOSE_LEAD_MUTATION } from 'src/graphql/acquisition/lead-management'

import { ConfirmButton } from './ConfirmButton'

export const CLOSE_REASONS: Record<
  Exclude<LeadCloseReasonEnum, 'OTHER'>,
  string
> = {
  FINANCIAL_OBJECTION: 'Objeção financeira',
  NO_CLASS_FOR_AGE: 'Sem turma para idade do aluno',
  UNDERAGE_NOT_DECISION_MAKER: 'Menor idade/Não é o decisor final',
  DOES_NOT_WANT_ONLINE: 'Não quer estudar online (5115)',
  INCOMPATIBLE_CLASS: 'Turma incompatível',
  DID_NOT_INTERACT: 'Não interagiu',
}

type CloseLeadFormProps = {
  lead: Lead
}

function CloseLeadAction({ lead }: CloseLeadFormProps) {
  const [opened, { open, close }] = useDisclosure(false)

  const [closeLead, { loading: closeLeadLoading }] = useMutation<
    CloseLeadMutation,
    CloseLeadMutationVariables
  >(CLOSE_LEAD_MUTATION, {
    refetchQueries: [
      'ListLeadsByStatusQuery',
      'ListCustomerEventsQuery',
      'GetLeadQuery',
    ],
  })

  const form = useForm({
    initialValues: {
      success: false,
      reason: null,
      note: '',
    },
    validate: {
      reason: (value, values) =>
        !value && values?.success === false ? 'Selecione um motivo' : null,
    },
    onValuesChange(values, previous) {
      if (values.success !== previous.success) {
        form.setFieldValue('reason', null)
        form.setFieldValue('note', '')
      }
    },
  })
  const handleSubmit = () => {
    closeLead({
      variables: { input: { leadId: lead.id, ...form.values } },
      onCompleted: () => {
        close()
      },
    })
  }

  const successClosed = form.values.success
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          form.reset()
          close()
        }}
        title="Finalizar oportunidade"
        centered
        overlayProps={{ opacity: 0.8 }}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <Group>
              <Text size="sm">Convertido com sucesso?</Text>
              <Switch
                onLabel="Sim"
                color="green"
                offLabel="Não"
                size="xl"
                {...form.getInputProps('success')}
              />
            </Group>

            <Textarea
              label="Nota (opcional)"
              placeholder="Adicione mais detalhes sobre o fechamento da oportunidade"
              {...form.getInputProps('note')}
            />

            <Select
              label="Motivo"
              disabled={successClosed}
              w={290}
              required={!successClosed}
              data={Object.entries(CLOSE_REASONS).map(([value, label]) => ({
                value,
                label,
              }))}
              placeholder="Selecione o motivo"
              wrapperProps={{ direction: 'row', d: 'flex' }}
              style={{ visibility: successClosed ? 'hidden' : 'visible' }}
              {...form.getInputProps('reason')}
            />

            <ConfirmButton
              confirmQuestion="Confirmar fechamento?"
              onClick={() => form.onSubmit(handleSubmit)()}
              loading={closeLeadLoading}
            >
              Finalizar oportunidade
            </ConfirmButton>
          </Stack>
        </form>
      </Modal>

      <Button size="compact-md" radius="sm" fz="xs" onClick={open}>
        Finalizar atendimento
      </Button>
    </>
  )
}

export default CloseLeadAction

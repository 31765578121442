import { Group, Loader, Stack, Text, Timeline, Tooltip } from '@mantine/core'
import {
  IconArrowBackUp,
  IconCheck,
  IconMessageCirclePlus,
  IconTargetArrow,
  IconUserCheck,
  IconUserPlus,
  IconX,
} from '@tabler/icons-react'
import { CustomerEventType } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { LIST_CUSTOMER_EVENTS_QUERY } from 'src/graphql/acquisition/lead-management'
import { dayjs, formatDate } from 'src/lib/date'
import { formatDateRelative } from 'src/lib/date'

type Props = {
  customerId: number
}

const typesLabels: Record<CustomerEventType, string> = {
  INTERACTED: 'Cliente interagiu',
  LEAD_ASSIGNED: 'Oportunidade atribuída',
  LEAD_CREATED: 'Oportunidade criada',
  LEAD_CLOSED_SUCCESSFULLY: 'Oportunidade finalizada com sucesso',
  LEAD_CLOSED_UNSUCCESSFULLY: 'Oportunidade finalizada sem sucesso',
  LEAD_REOPENED: 'Oportunidade reaberta',
  MARKETING_MESSAGE_SENT: 'Mensagem de marketing enviada',
}

const typesIcons: Record<CustomerEventType, React.ReactNode> = {
  INTERACTED: <IconMessageCirclePlus size={18} strokeWidth={2} />,
  LEAD_ASSIGNED: <IconUserCheck size={18} strokeWidth={2} />,
  LEAD_CREATED: <IconUserPlus size={18} strokeWidth={2} />,
  LEAD_CLOSED_SUCCESSFULLY: <IconCheck size={18} strokeWidth={2} />,
  LEAD_CLOSED_UNSUCCESSFULLY: <IconX size={18} strokeWidth={2} />,
  MARKETING_MESSAGE_SENT: <IconTargetArrow size={18} strokeWidth={2} />,
  LEAD_REOPENED: <IconArrowBackUp size={18} strokeWidth={2} />,
}

export function CustomerEventsTimeline({ customerId }: Props) {
  const { data, loading } = useQuery(LIST_CUSTOMER_EVENTS_QUERY, {
    variables: { customerId },
  })

  return (
    <Stack m="md">
      {!loading && data?.ListCustomerEvents.length === 0 && (
        <Text c="dimmed" size="sm">
          Nenhum evento registrado
        </Text>
      )}

      {loading && (
        <Group justify="center" align="center">
          <Loader size="sm" type="dots" color="dark.3" />
        </Group>
      )}

      {data?.ListCustomerEvents.length > 0 && (
        <Timeline
          mt="sm"
          active={0}
          bulletSize={36}
          lineWidth={3}
          styles={{
            itemTitle: {
              fontSize: '14px',
            },
          }}
        >
          {data?.ListCustomerEvents.map((event) => (
            <Timeline.Item
              key={event.id}
              bullet={typesIcons[event.type]}
              title={typesLabels[event.type]}
            >
              <Text c="dark.3" size="xs">
                {event.message}
              </Text>

              <Tooltip label={formatDate(event.createdAt)}>
                <Text c="dimmed" size="xs" mt={4}>
                  {dayjs(event.createdAt).isSame(dayjs(), 'day')
                    ? formatDateRelative(event.createdAt)
                    : formatDate(event.createdAt)}
                </Text>
              </Tooltip>
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </Stack>
  )
}

import { Group, Button, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useIsSmallScreen, useIsVerySmallScreen } from 'src/hooks/shared/screen'

type NegativeFeedbackFooterProps = {
  onSkip: () => void
  onTryAgain: () => void
}

const NegativeFeedbackFooter = ({
  onTryAgain,
  onSkip,
}: NegativeFeedbackFooterProps) => {
  const { t } = useTranslation('learning')

  const isSmallScreen = useIsSmallScreen()
  const isVerySmallScreen = useIsVerySmallScreen()

  const isDesktop = !isVerySmallScreen && !isSmallScreen

  return (
    <Group justify="space-between" w="100%" data-sel="negative-feedback-footer">
      <Button
        onClick={onSkip}
        bg="white"
        c="black"
        size={isVerySmallScreen || isDesktop ? 'sm' : 'lg'}
        h={rem(50)}
        fw={500}
        styles={{
          root: {
            border: '1px solid var(--mantine-color-gray-3)',
          },
        }}
        data-sel="skip-button"
      >
        {t('common.skip')}
      </Button>
      <Button
        data-sel="try-again-button"
        onClick={onTryAgain}
        size={isVerySmallScreen || isDesktop ? 'sm' : 'md'}
        h={rem(50)}
        fw={500}
      >
        {t('student.activity-execution.try-again')}
      </Button>
    </Group>
  )
}

export default NegativeFeedbackFooter

import { Badge, Group, Select, Skeleton, SelectProps } from '@mantine/core'
import { IconUser } from '@tabler/icons-react'
import {
  ListSalesConsultants,
  ListSalesConsultantsVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { LIST_SALES_CONSULTANTS } from 'src/graphql/acquisition/reports'

// Extend the standard data item type
interface ConsultantSelectItem {
  value: string
  label: string
  activeLeadsCount?: number | null
}

type SelectSalesConsultantProps = {
  onSelect: (salesConsultantId: number) => void
  value: number
  showActiveBadge?: boolean
  selectProps?: Partial<SelectProps>
}

function SelectSalesConsultant({
  onSelect,
  value,
  showActiveBadge = false,
  selectProps,
}: SelectSalesConsultantProps) {
  const { data: salesConsultantsData, loading: salesConsultantsLoading } =
    useQuery<ListSalesConsultants, ListSalesConsultantsVariables>(
      LIST_SALES_CONSULTANTS
    )

  const salesConsultantsOptions: ConsultantSelectItem[] =
    salesConsultantsData?.ListSalesConsultants.map((consultant) => ({
      value: consultant.id.toString(),
      label: consultant.name,
      activeLeadsCount: consultant.activeLeadsCount || 0,
    })) || []

  const renderOption: SelectProps['renderOption'] = ({ option }) => {
    // Cast the option to our custom type
    const customOption = option as ConsultantSelectItem

    return (
      <Group justify="space-between" wrap="nowrap" w="100%">
        <span>{customOption.label}</span>

        {showActiveBadge &&
          customOption.activeLeadsCount &&
          customOption.activeLeadsCount > 0 && (
            <Badge variant="filled" color="orange">
              {customOption.activeLeadsCount}
            </Badge>
          )}
      </Group>
    )
  }

  if (salesConsultantsLoading && !salesConsultantsData)
    return <Skeleton w={200} h={40} />

  return (
    <Select
      value={value?.toString()}
      onChange={(value) => onSelect(Number(value))}
      leftSection={<IconUser size={16} />}
      data={salesConsultantsOptions}
      placeholder="Consultor"
      clearable
      renderOption={renderOption}
      {...selectProps}
    />
  )
}

export default SelectSalesConsultant

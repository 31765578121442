import {
  Group,
  Stack,
  ThemeIcon,
  Title,
  Text,
  Box,
  Button,
  Space,
  Table,
  ActionIcon,
  Tooltip,
  Anchor,
  Skeleton,
} from '@mantine/core'
import { IconEye, IconPlus, IconTargetArrow } from '@tabler/icons-react'

import { Link, navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { formatDate } from 'src/lib/date'

import { CampaignStatusBadge } from './components/CampaignInfo'

const LIST_CAMPAIGNS = gql`
  query ListCampaigns {
    ListCampaigns {
      id
      status
      name
      stats {
        total
        delivered
        interacted
        conversionRate
        deliveredWithErrors
      }
      description
      createdAt
    }
  }
`

const LoadingState = () => {
  return (
    <Box m="xl">
      <Stack gap="md">
        <Skeleton height={20} width={200} />

        <Group gap="xs">
          <Skeleton height={36} width={36} radius="xl" />
          <Skeleton height={28} width={150} />
          <Skeleton height={32} width={120} radius="sm" ml="xs" />
        </Group>

        <Space h="xs" />

        <Box px="sm">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th />
                <Table.Th w={120}>Status</Table.Th>
                <Table.Th w={250}>Nome</Table.Th>
                <Table.Th>Descrição</Table.Th>
                <Table.Th>Criado em</Table.Th>
                <Table.Th>Total</Table.Th>
                <Table.Th>Entregues</Table.Th>
                <Table.Th>Interagiram</Table.Th>
                <Table.Th>Conversão</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {Array.from({ length: 5 }).map((_, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Skeleton height={24} width={24} radius="xl" />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={22} width={80} radius="xl" />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width={180} />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width="100%" />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width={100} />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width={60} />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width={60} />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width={60} />
                  </Table.Td>
                  <Table.Td>
                    <Skeleton height={20} width={60} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Box>
      </Stack>
    </Box>
  )
}

export const CampaignsPage = () => {
  const { currentUser } = useAuth()
  const { data, loading } = useQuery(LIST_CAMPAIGNS)

  if (loading) return <LoadingState />

  const campaigns = data.ListCampaigns

  const openCampaignHandler = (campaignId: number) => () => {
    navigate(routes.campaign({ campaignId }))
  }

  return (
    <>
      <Metadata title="Campanhas" description="Lista de campanhas" />

      <Box m="xl">
        <Stack gap="md">
          <Text fz="sm" c="dimmed">
            {currentUser.salesTeam?.name ?? 'Visão de administrador'}
          </Text>

          <Group gap="xs">
            <ThemeIcon size="lg" color="dark.9" variant="transparent">
              <IconTargetArrow size={22} />
            </ThemeIcon>

            <Title order={3}>Campanhas</Title>

            <Button
              ml="xs"
              size="compact-sm"
              radius="sm"
              leftSection={<IconPlus size={16} />}
              onClick={() => navigate(routes.newCampaign())}
            >
              Nova campanha
            </Button>
          </Group>

          <Space h="xs" />

          <Box px="sm">
            {campaigns.length === 0 && (
              <Text c="dimmed" fz="sm">
                Nenhuma campanha encontrada. Crie uma nova campanha para começar
                a enviar mensagens.
              </Text>
            )}

            {campaigns.length > 0 && (
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th />
                    <Table.Th w={120}>Status</Table.Th>
                    <Table.Th w={250}>Nome</Table.Th>
                    <Table.Th>Descrição</Table.Th>
                    <Table.Th>Criado em</Table.Th>
                    <Table.Th>Total</Table.Th>
                    <Table.Th>Entregues</Table.Th>
                    <Table.Th>Interagiram</Table.Th>
                    <Table.Th>Conversão</Table.Th>
                  </Table.Tr>
                </Table.Thead>

                {campaigns.map((campaign) => (
                  <Table.Tr key={campaign.id}>
                    <Table.Td>
                      <Group gap="xs">
                        <Tooltip label="Ver campanha">
                          <ActionIcon
                            size="sm"
                            color="dark.6"
                            radius="xl"
                            onClick={openCampaignHandler(campaign.id)}
                          >
                            <IconEye size={16} />
                          </ActionIcon>
                        </Tooltip>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <CampaignStatusBadge status={campaign.status} />
                    </Table.Td>
                    <Table.Td>
                      <Anchor
                        component={Link}
                        to={routes.campaign({ campaignId: campaign.id })}
                      >
                        {campaign.name}
                      </Anchor>
                    </Table.Td>
                    <Table.Td>
                      <Text fz="sm" c="dimmed" lineClamp={1}>
                        {campaign.description}
                      </Text>
                    </Table.Td>
                    <Table.Td>{formatDate(campaign.createdAt)}</Table.Td>
                    <Table.Td ta="center">{campaign.stats.total}</Table.Td>
                    <Table.Td ta="center">{campaign.stats.delivered}</Table.Td>
                    <Table.Td ta="center">{campaign.stats.interacted}</Table.Td>
                    <Table.Td ta="center">
                      {campaign.stats.conversionRate}%
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export default CampaignsPage

import { useState } from 'react'

import data from '@emoji-mart/data'
import i18n from '@emoji-mart/data/i18n/pt.json'
import Picker from '@emoji-mart/react'
import {
  Button,
  Group,
  Stack,
  Text,
  Textarea,
  Transition,
  ActionIcon,
  Alert,
  HoverCard,
  Tooltip,
  Popover,
} from '@mantine/core'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import {
  IconAlertCircle,
  IconMicrophoneFilled,
  IconMoodSmile,
  IconTargetArrow,
} from '@tabler/icons-react'
import { GetCustomerChatQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import {
  SEND_MESSAGE_MUTATION,
  SEND_MARKETING_MESSAGE_MUTATION,
} from 'src/graphql/acquisition/lead-management'
import { useEmojiPicker } from 'src/hooks/acquisition/chat'
import { CampaignMessagePreview } from 'src/pages/Acquisition/campaigns/CampaignsPage/components/CampaignInfo'
import { MessageTemplateSelect } from 'src/pages/Acquisition/campaigns/NewCampaignPage/NewCampaignPage'
import { RecordAudioForm } from 'src/pages/Acquisition/LeadsPage/components/Chat/RecordAudioForm'

import { EmojiPicker } from './EmojiPicker'

const REASON_CANT_SEND_MESSAGE = {
  lead_not_owned: 'Oportunidade não está atribuída a você',
  customer_not_interacted_in_last_24_hours:
    'Você não pode enviar mensagens para o cliente se ele não interagiu com o nosso WhatsApp nas últimas 24 horas.',
}

type SendMessageFormProps = {
  customerId: number
  canSendMessage?: GetCustomerChatQuery['GetCustomerChat']['canSendMessage']
  reasonCantSendMessage?: GetCustomerChatQuery['GetCustomerChat']['reasonCantSendMessage']
  suggestions?: GetCustomerChatQuery['GetCustomerChat']['suggestions']
}

const SendMarketingMessageTemplate = ({
  customerId,
  canSendMessage,
  reasonCantSendMessage,
}: {
  customerId: number
  canSendMessage: boolean
  reasonCantSendMessage: string
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<{
    id: string
    name: string
    content: string
  } | null>(null)
  const [opened, { open, close }] = useDisclosure(false)
  const ref = useClickOutside(() => close())

  const [sendMarketingMessage, { loading }] = useMutation(
    SEND_MARKETING_MESSAGE_MUTATION,
    {
      refetchQueries: ['GetCustomerChatQuery', 'ListCustomerEventsQuery'],
    }
  )

  const handleSendMarketingMessage = () => {
    if (!customerId || !selectedTemplate) return

    sendMarketingMessage({
      variables: {
        input: {
          customerId,
          contentSid: selectedTemplate.id,
        },
      },
      onCompleted: () => {
        close()
        setSelectedTemplate(null)
      },
    })
  }

  return (
    <Popover opened={opened} trapFocus withArrow withinPortal withOverlay>
      <Popover.Target>
        <Tooltip label="Enviar mensagem de marketing">
          <ActionIcon
            size="md"
            onClick={open}
            variant={
              canSendMessage
                ? 'outline'
                : !canSendMessage &&
                    reasonCantSendMessage !==
                      'customer_not_interacted_in_last_24_hours'
                  ? 'outline'
                  : 'filled'
            }
            color={
              canSendMessage
                ? 'dark.3'
                : !canSendMessage &&
                    reasonCantSendMessage !==
                      'customer_not_interacted_in_last_24_hours'
                  ? 'dark.3'
                  : 'violet.6'
            }
            disabled={
              !canSendMessage &&
              reasonCantSendMessage !==
                'customer_not_interacted_in_last_24_hours'
            }
          >
            <IconTargetArrow size={16} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown ref={ref}>
        <Stack>
          <Text>Enviar mensagem de marketing</Text>

          <MessageTemplateSelect
            onMessageTemplateChange={(template) => {
              setSelectedTemplate(template)
            }}
            value={selectedTemplate?.id}
          />

          {selectedTemplate && (
            <Stack>
              <CampaignMessagePreview messageTemplate={selectedTemplate} />

              <Group gap="xs" justify="flex-end">
                <Button
                  size="compact-sm"
                  fz="xs"
                  variant="light"
                  onClick={() => {
                    setSelectedTemplate(null)
                    close()
                  }}
                >
                  Cancelar
                </Button>

                <Button
                  size="compact-sm"
                  fz="xs"
                  loading={loading}
                  onClick={handleSendMarketingMessage}
                >
                  Enviar
                </Button>
              </Group>
            </Stack>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

const SendMessageForm = ({
  customerId,
  canSendMessage,
  reasonCantSendMessage,
  suggestions = [],
}: SendMessageFormProps) => {
  const { value, setValue, inputRef, emojiResults, insertEmoji, isOpened } =
    useEmojiPicker<HTMLTextAreaElement>()
  const [recordingAudio, { toggle: toggleRecordingAudio }] =
    useDisclosure(false)

  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE_MUTATION, {
    refetchQueries: ['GetCustomerChatQuery'],
  })

  const handleSendMessage = (message: string) => {
    if (!message.trim()) return

    sendMessage({
      variables: {
        input: {
          message,
          customerId: Number(customerId),
        },
      },
      onCompleted: () => {
        setValue('')
      },
    })
  }

  const handleSendAudioMessage = (audioFileUrl: string) => {
    sendMessage({
      variables: {
        input: {
          media: {
            type: 'AUDIO',
            url: audioFileUrl,
          },
          customerId: Number(customerId),
        },
      },
      onCompleted: () => {
        setValue('')
        toggleRecordingAudio()
      },
    })
  }

  return (
    <Stack
      p="md"
      bg={canSendMessage ? 'white' : 'orange.0'}
      style={{ borderTop: '1px solid #CCC' }}
    >
      {!canSendMessage &&
        reasonCantSendMessage !==
          'customer_not_interacted_in_last_24_hours' && (
          <Group justify="center">
            <Alert
              p={0}
              icon={<IconAlertCircle size={16} />}
              variant="transparent"
              color="yellow"
            >
              {
                REASON_CANT_SEND_MESSAGE[
                  reasonCantSendMessage as keyof typeof REASON_CANT_SEND_MESSAGE
                ]
              }
            </Alert>
          </Group>
        )}

      {(canSendMessage ||
        reasonCantSendMessage ===
          'customer_not_interacted_in_last_24_hours') && (
        <>
          {recordingAudio ? (
            <RecordAudioForm
              onTrashClick={toggleRecordingAudio}
              onSend={handleSendAudioMessage}
              customerId={customerId}
            />
          ) : (
            <Stack gap="xs">
              <Group gap={5} justify="stretch" wrap="nowrap">
                <Popover trapFocus={false} returnFocus>
                  <Popover.Target>
                    <Tooltip label="Adicionar emoji">
                      <ActionIcon
                        variant="outline"
                        color="dark.3"
                        disabled={!canSendMessage}
                      >
                        <IconMoodSmile size={16} />
                      </ActionIcon>
                    </Tooltip>
                  </Popover.Target>

                  <Popover.Dropdown p={0} bd={0}>
                    <Picker
                      theme="light"
                      size={20}
                      data={data}
                      i18n={i18n}
                      style={{ border: 'none' }}
                      onEmojiSelect={(emoji) => {
                        setValue(value + emoji.native)
                      }}
                    />
                  </Popover.Dropdown>
                </Popover>

                <Tooltip label="Gravar áudio">
                  <ActionIcon
                    size="md"
                    onClick={toggleRecordingAudio}
                    variant="outline"
                    color="dark.3"
                    disabled={!canSendMessage}
                  >
                    <IconMicrophoneFilled size={16} />
                  </ActionIcon>
                </Tooltip>

                <SendMarketingMessageTemplate
                  customerId={customerId}
                  canSendMessage={canSendMessage}
                  reasonCantSendMessage={reasonCantSendMessage}
                />

                <Transition
                  mounted={
                    !recordingAudio && suggestions.length > 0 && canSendMessage
                  }
                  transition="pop-bottom-right"
                  duration={500}
                >
                  {(styles) => (
                    <Group
                      gap="xs"
                      wrap="nowrap"
                      ml="md"
                      style={{ ...styles, overflow: 'hidden' }}
                    >
                      {suggestions.map((suggestion) => (
                        <HoverCard
                          key={suggestion.id}
                          shadow="md"
                          radius="md"
                          position="top"
                          styles={{
                            dropdown: {
                              backgroundColor: 'var(--mantine-color-dark-4)',
                              color: 'white',
                              border: 'none',
                            },
                          }}
                        >
                          <HoverCard.Target>
                            <Button
                              key={suggestion.id}
                              size="compact-md"
                              fw="normal"
                              color="violet"
                              variant="light"
                              ta="center"
                              onClick={() => setValue(suggestion.content)}
                              style={{ cursor: 'pointer' }}
                            >
                              <Text size="sm" truncate="end">
                                {suggestion.content}
                              </Text>
                            </Button>
                          </HoverCard.Target>
                          <HoverCard.Dropdown w={300}>
                            <Text size="sm">{suggestion.content}</Text>
                          </HoverCard.Dropdown>
                        </HoverCard>
                      ))}
                    </Group>
                  )}
                </Transition>
              </Group>

              <Group gap="sm" pos="relative">
                <EmojiPicker
                  onSelect={insertEmoji}
                  emojis={emojiResults}
                  opened={isOpened}
                >
                  <Textarea
                    ref={inputRef}
                    autosize
                    placeholder={
                      canSendMessage
                        ? 'Digite uma mensagem...'
                        : REASON_CANT_SEND_MESSAGE[
                            reasonCantSendMessage as keyof typeof REASON_CANT_SEND_MESSAGE
                          ]
                    }
                    maxRows={10}
                    value={value}
                    onChange={(e) => {
                      setValue(e.currentTarget.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (e.shiftKey || e.altKey) {
                          // Allow shift and alt key to enter new lines
                          return
                        }
                        e.preventDefault()
                        handleSendMessage(value)
                      }
                    }}
                    style={{ flex: 1 }}
                    disabled={!canSendMessage}
                  />
                </EmojiPicker>

                <Button
                  onClick={() => handleSendMessage(value)}
                  loading={loading}
                  disabled={loading || !canSendMessage}
                >
                  Enviar
                </Button>
              </Group>
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}

export default SendMessageForm

export const GET_LEAD_FUNNEL = gql`
  query GetLeadFunnel($filters: ReportFilters!) {
    GetLeadFunnel(filters: $filters) {
      total
      interacted
      converted
    }
  }
`

export const GET_CONVERSION_RATE = gql`
  query GetConversionRate($filters: ReportFilters!) {
    GetConversionRate(filters: $filters) {
      conversionRate
    }
  }
`

export const GET_LEADS_BY_DAY = gql`
  query GetLeadsByDay($filters: ReportFilters!) {
    GetLeadsByDay(filters: $filters) {
      date
      count
    }
  }
`

export const GET_AVERAGE_TIME_TO_FINISH = gql`
  query GetAverageTimeToFinish($filters: ReportFilters!) {
    GetAverageTimeToFinish(filters: $filters) {
      averageTimeInDays
    }
  }
`

export const LIST_SALES_CONSULTANTS = gql`
  query ListSalesConsultants {
    ListSalesConsultants {
      id
      name
      activeLeadsCount
    }
  }
`

export const GET_LEADS_BY_STATUS = gql`
  query GetLeadsByStatus($filters: ReportFilters!) {
    GetLeadsByStatus(filters: $filters) {
      pending
      inProgress
      converted
      lost
    }
  }
`

export const GET_LEADS_BY_CLOSE_REASON = gql`
  query GetLeadsByCloseReason($filters: ReportFilters!) {
    GetLeadsByCloseReason(filters: $filters) {
      totals {
        closeReason
        count
      }
    }
  }
`

export const GET_AVERAGE_QUEUE_TIME = gql`
  query GetAverageQueueTime($filters: ReportFilters!) {
    GetAverageQueueTime(filters: $filters) {
      averageTimeInMinutes
    }
  }
`

import {
  Text,
  Stack,
  Title,
  Divider,
  Loader,
  Card,
  Group,
  Button,
  Modal,
  Textarea,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import { CustomerNote } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import {
  CREATE_CUSTOMER_NOTE,
  LIST_CUSTOMER_NOTES,
} from 'src/graphql/acquisition/customer-notes'
import { formatDate } from 'src/lib/date'

type CustomerNotesProps = {
  customerId: number
}

const AddCustomerNote = ({ customerId }: { customerId: number }) => {
  const [createCustomerNote, { loading }] = useMutation(CREATE_CUSTOMER_NOTE, {
    refetchQueries: [LIST_CUSTOMER_NOTES],
  })
  const [opened, { open, close }] = useDisclosure(false)

  const form = useForm({
    initialValues: {
      note: '',
    },
    validate: {
      note: (value) => (value.length < 10 ? 'Mínimo de 10 caracteres' : null),
    },
  })

  const handleSubmit = (values: typeof form.values) => {
    createCustomerNote({
      variables: { input: { customerId, note: values.note } },
      onCompleted: () => {
        form.reset()
        close()
      },
    })
  }

  return (
    <>
      <Button
        size="compact-xs"
        leftSection={<IconPlus size={16} />}
        onClick={open}
      >
        Nova anotação
      </Button>

      <Modal title="Nova anotação" opened={opened} onClose={close} centered>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack gap="xs">
            <Text size="xs" ta="center" c="dark.3">
              Estas anotações sobre a oportunidade são privadas e compartilhadas
              entre todos os atendentes da equipe.
            </Text>

            <Textarea
              placeholder="Digite sua anotação"
              minRows={4}
              autosize
              maxRows={10}
              disabled={loading}
              required
              {...form.getInputProps('note')}
            />

            <Group justify="flex-end">
              <Button
                size="compact-sm"
                variant="outline"
                onClick={close}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button size="compact-sm" type="submit" loading={loading}>
                Adicionar
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  )
}

const CustomerNoteItem = ({ note }: { note: CustomerNote }) => {
  return (
    <Card withBorder>
      <Stack gap="xs">
        <Text size="xs" fs="italic">
          {note.note}
        </Text>

        <Group justify="space-between">
          <Text fz={10} ta="right" c="dark.3">
            {formatDate(note.createdAt, 'DD/MM/YYYY HH:mm')}
          </Text>

          <Text fz={11} ta="right" c="dark.3">
            {note.salesConsultant.name}
          </Text>
        </Group>
      </Stack>
    </Card>
  )
}

export default function CustomerNotes({ customerId }: CustomerNotesProps) {
  const { data, loading } = useQuery(LIST_CUSTOMER_NOTES, {
    variables: { customerId },
  })

  return (
    <Stack p="md">
      <Group>
        <Title order={5}>Anotações</Title>

        <AddCustomerNote customerId={customerId} />
      </Group>

      <Text size="xs" c="dark.3">
        Estas anotações sobre a oportunidade são privadas e compartilhadas entre
        todos os atendentes da equipe.
      </Text>

      <Divider />

      {loading && <Loader type="dots" />}
      {!loading && data?.ListCustomerNotes.length === 0 && (
        <Text size="xs" c="dark.3" ta="center">
          Sem anotações
        </Text>
      )}
      {!loading && data?.ListCustomerNotes.length > 0 && (
        <Stack gap="xs">
          {data.ListCustomerNotes.map((note) => (
            <CustomerNoteItem key={note.id} note={note} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

import { useEffect } from 'react'

import {
  Box,
  Container,
  Group,
  Stack,
  ThemeIcon,
  Title,
  Text,
  Tooltip,
  ActionIcon,
  Divider,
  SimpleGrid,
  Skeleton,
} from '@mantine/core'
import { IconArrowLeft, IconRocket, IconTargetArrow } from '@tabler/icons-react'
import {
  GetCampaign,
  GetCampaignVariables,
  ListCampaignCustomers,
  ListCampaignCustomersVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { useFeatureFlags } from 'src/hooks/shared/useFeatureFlags'

import ReportCard from '../../ReportsPage/components/ReportCard'
import { CampaignInfo } from '../CampaignsPage/components/CampaignInfo'
import { CustomerCampaignList } from '../NewCampaignPage/components/CustomerCampaignList'

type CampaignPageProps = {
  campaignId: number
}

export const GET_CAMPAIGN = gql`
  query GetCampaign($id: Int!) {
    GetCampaign(id: $id) {
      id
      status
      name
      description
      filters {
        lead {
          idiom
          ageGroup
          knowledgeLevel
          closeReason
          closedInLast30Days
          closedInLast7Days
          selectedFranchiseeIds
        }
      }
      messageTemplate {
        id
        name
        content
      }
      stats {
        total
        delivered
        interacted
        conversionRate
        deliveredWithErrors
      }
    }
  }
`

export const LIST_CAMPAIGN_CUSTOMERS = gql`
  query ListCampaignCustomers($campaignId: Int!) {
    ListCampaignCustomers(campaignId: $campaignId) {
      page
      total
      customers {
        id
        name
        email
        phone
        leads {
          id
          product {
            idiom
          }
        }
      }
    }
  }
`

const LoadingState = () => {
  return (
    <Box m="xl">
      <Stack gap="md">
        <Skeleton height={20} width={200} />

        <Group gap="xs">
          <Skeleton height={36} width={36} radius="xl" />
          <Skeleton height={36} width={36} radius="xl" />
          <Skeleton height={28} width={150} />
        </Group>
      </Stack>

      <Container fluid mt="xl">
        <Stack gap="lg">
          <Skeleton height={120} radius="md" />

          <Divider my="lg" />

          <Stack>
            <Group gap="xs">
              <Skeleton height={36} width={36} radius="xl" />
              <Skeleton height={24} width={120} />
            </Group>

            <SimpleGrid cols={8}>
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} height={140} radius="md" />
              ))}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export const CampaignPage = ({ campaignId }: CampaignPageProps) => {
  const { currentUser } = useAuth()
  const { canAccessCampaigns } = useFeatureFlags()

  useEffect(() => {
    if (!canAccessCampaigns) {
      navigate(routes.leads())
    }
  }, [canAccessCampaigns])

  const { data: campaignData, loading: campaignLoading } = useQuery<
    GetCampaign,
    GetCampaignVariables
  >(GET_CAMPAIGN, {
    variables: { id: campaignId },
  })

  const { data: customersData, loading: customersLoading } = useQuery<
    ListCampaignCustomers,
    ListCampaignCustomersVariables
  >(LIST_CAMPAIGN_CUSTOMERS, {
    variables: { campaignId },
  })

  if (campaignLoading || customersLoading) return <LoadingState />

  const campaign = campaignData?.GetCampaign
  const customers = customersData?.ListCampaignCustomers.customers || []

  return (
    <Box m="xl">
      <Stack gap="md">
        <Text fz="sm" c="dimmed">
          {currentUser.salesTeam.name}
        </Text>

        <Group gap="xs">
          <Tooltip label="Voltar à lista de campanhas">
            <ActionIcon
              color="dark.9"
              radius="xl"
              onClick={() => navigate(routes.campaigns())}
            >
              <IconArrowLeft size={22} />
            </ActionIcon>
          </Tooltip>

          <ThemeIcon size="lg" color="dark.9" variant="transparent">
            <IconTargetArrow size={22} />
          </ThemeIcon>

          <Title order={3}>Campanhas</Title>
        </Group>
      </Stack>

      <Container fluid mt="xl">
        <CampaignInfo campaign={campaign} />

        <Divider my="lg" />

        <Stack>
          <Group gap="xs">
            <ThemeIcon size="lg" color="dark.9" variant="transparent">
              <IconRocket size={22} />
            </ThemeIcon>

            <Title order={4}>Performance</Title>
          </Group>

          <SimpleGrid cols={8}>
            <ReportCard size="sm">
              <Stack justify="center">
                <Title order={5} ta="center">
                  Total
                </Title>

                <Text ta="center" fz={36} fw={600}>
                  {campaign.stats.total}
                </Text>
              </Stack>
            </ReportCard>

            <ReportCard size="sm">
              <Stack justify="center">
                <Title order={5} ta="center">
                  Entregues
                </Title>

                <Text ta="center" fz={36} fw={600}>
                  {campaign.stats.delivered}
                </Text>
              </Stack>
            </ReportCard>

            <ReportCard size="sm">
              <Stack justify="center">
                <Title order={5} ta="center">
                  Erros
                </Title>

                <Text ta="center" fz={36} fw={600}>
                  {campaign.stats.deliveredWithErrors}
                </Text>
              </Stack>
            </ReportCard>

            <ReportCard size="sm">
              <Stack justify="center">
                <Title order={5} ta="center">
                  Interagiram
                </Title>

                <Text ta="center" fz={36} fw={600}>
                  {campaign.stats.interacted}
                </Text>
              </Stack>
            </ReportCard>

            <ReportCard size="sm">
              <Stack justify="center">
                <Title order={5} ta="center">
                  Conversão
                </Title>

                <Text ta="center" fz={36} fw={600}>
                  {campaign.stats.conversionRate}%
                </Text>
              </Stack>
            </ReportCard>
          </SimpleGrid>
        </Stack>

        <Divider my="lg" />

        <Stack>
          <Group gap="xs">
            <ThemeIcon size="lg" color="dark.9" variant="transparent">
              <IconTargetArrow size={22} />
            </ThemeIcon>

            <Title order={4}>Público-alvo</Title>
          </Group>

          <CustomerCampaignList
            customers={customers}
            total={campaign.stats.total}
            loading={campaignLoading || customersLoading}
            hasFilters={true}
          />
        </Stack>
      </Container>
    </Box>
  )
}

export default CampaignPage
